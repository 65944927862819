import {Box} from '@mui/material';
import {Zoom} from '../../Zoom';
import React from 'react';
import {fullCover} from '../../../styles';
import {characterFontFamily} from '../../../styles/theme';

export function Character(props: {value: string}) {
    return (
        <Box
            display="flex"
            sx={[
                fullCover,
                {
                    p: '0.5rem',
                    backgroundColor: 'black',
                    fontFamily: characterFontFamily,
                },
            ]}
            justifyContent="center"
            alignItems="center"
        >
            <Zoom>
                <Box>{props.value}</Box>
            </Zoom>
        </Box>
    );
}
