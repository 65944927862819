import React, {useEffect, useState} from 'react';
import {Box, SxProps, Theme} from '@mui/material';
import {fullCover} from '../../../styles';
import {Zoom} from '../../Zoom';
import {characterFontFamily} from '../../../styles/theme';
import {ALL_TASKS} from '../../../sessions/tasks';
import {TaskType} from '../../../Pages/assignments';

type ColoredWordsProps = {};

const COLORS = ALL_TASKS.filter(
    t => t.type === TaskType.Color && t.id !== 'black',
);

export function ColoredWords() {
    const [word, setWord] = useState<string>('');
    const [color, setColor] = useState<string>('');

    useEffect(() => {
        onNext();
    }, []);

    function onNext(): void {
        const randomWord: string =
            COLORS[
                Math.floor(Math.random() * COLORS.length)
            ].label.toUpperCase();
        const randomColor: string =
            COLORS[Math.floor(Math.random() * COLORS.length)].value;
        setWord(randomWord);
        setColor(randomColor);
    }

    return (
        <Box
            sx={[
                fullCover,
                {
                    backgroundColor: 'black',
                    p: '10px',
                    userSelect: 'none',
                },
            ]}
            onClick={onNext}
        >
            <Box
                sx={
                    [
                        {
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontFamily: characterFontFamily,
                            color: color,
                            height: '100%',
                        },
                    ] as SxProps<Theme>
                }
            >
                <Zoom>{word}</Zoom>
            </Box>
        </Box>
    );
}
