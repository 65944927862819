import {useMemo} from 'react';
import {useLocation} from 'react-router-dom';

export function useQueryParam(key: string): string | undefined {
    const {search} = useLocation();

    return useMemo(
        () => new URLSearchParams(search).get(key) ?? undefined,
        [search, key],
    );
}
