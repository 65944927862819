import {Box} from '@mui/material';
import React from 'react';

type YoutubeVideoProps = {
    src: string;
}

export function YoutubeVideo({src}: YoutubeVideoProps) {
    return <Box
        component="iframe"
        src={src}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
        sx={styles.youtubeVideo}
    />
}

const styles = {
    youtubeVideo: {
        aspectRatio: '16 / 9',
        width: '100%',
    },
};
