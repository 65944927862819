import type {ReactElement} from 'react';
import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {PracticeRounds} from './PracticeRounds';
import {SessionEnd} from '../SessionEnd/SessionEnd';
import {SessionIntro} from './SessionIntro';
import type {SessionMetrics} from './types';
import {useAppContext} from '../../contexts/AppContext';
import {useWakeLock} from '../../hooks/useWakeLock';
import {useAnalytics} from 'use-analytics';
import {Lesson} from '../Lesson/Lesson';
import {RelaxLesson} from '../Lesson/RelaxLesson';

export interface PracticeSessionProps {
    lesson: Lesson;
    quickStart: boolean;
}

enum PracticeSessionState {
    Intro,
    Practice,
    End,
}

export function PracticeSession({
    lesson,
    quickStart,
}: PracticeSessionProps): ReactElement {
    const [state, setState] = useState<PracticeSessionState>(
        PracticeSessionState.Intro,
    );
    const {track} = useAnalytics();
    const [metrics, setMetrics] = useState<SessionMetrics | null>(null);
    const [tutorialMode, setTutorialMode] = useState(false);
    const wakeLock = useWakeLock();
    const navigate = useNavigate();

    useEffect(() => {
        if (wakeLock.isSupported && state === PracticeSessionState.Practice) {
            void wakeLock.request();

            return () => wakeLock.release();
        }
    }, [wakeLock, state]);

    const goBack = () => {
        void track('session-goback', {
            lessonId: lesson.id,
            tutorial: tutorialMode,
        });

        if (tutorialMode) {
            setState(PracticeSessionState.Intro);
            setTutorialMode(false);
        } else {
            navigate(-1);
        }
    };

    const onStart = useCallback((bf: boolean) => {
        setTutorialMode(bf);
        setState(PracticeSessionState.Practice);
    }, []);

    const handlePracticeEnd = useCallback((m: SessionMetrics) => {
        setMetrics(m);
        setState(PracticeSessionState.End);
    }, []);

    if (state === PracticeSessionState.Practice) {
        return (
            <PracticeRounds
                lesson={lesson}
                onQuit={goBack}
                onEnd={handlePracticeEnd}
                tutorial={tutorialMode}
            />
        );
    }
    if (state === PracticeSessionState.End && metrics !== null) {
        return (
            <SessionEnd
                lessonId={lesson.id}
                metrics={metrics}
                persist={!tutorialMode && !(lesson instanceof RelaxLesson)}
            />
        );
    }

    return (
        <SessionIntro
            lesson={lesson}
            onBack={goBack}
            onStart={onStart}
            quickStart={quickStart}
        />
    );
}
