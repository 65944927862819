import {Round, Task} from '../../Pages/assignments';
import {SessionMetrics} from '../PracticeSession/types';
import {playAudio, playSentence} from '../../utility/playAudio';
import {getTasksByIds} from '../../utility/taskUtils';
import React, {ReactElement, ReactNode} from 'react';
import {delay} from '../../utility/delay';
import {LessonType} from '../Levels/types';
import {b64hash} from '../../utility/b64hash';

export const TOTAL_ROUNDS = 10;

export class Lesson {
    public readonly tasks: Task[];
    public readonly id: string;
    public readonly screenPreviewJSX: ReactNode;

    constructor(
        public readonly type: LessonType,
        id: string | undefined,
        public readonly totalRounds = TOTAL_ROUNDS,
        public readonly taskIds: string[],
    ) {
        this.tasks = getTasksByIds(taskIds);
        this.id =
            id ??
            b64hash(
                JSON.stringify({
                    type,
                    totalRounds,
                    taskIds: [...taskIds].sort(),
                }),
            );
    }

    public getRound(i: number): Round {
        throw new Error('Not implemented!');
    }

    public async onRoundStart(round: Round): Promise<void> {
        // playSentence is not awaited since the ding sound is too long
        void playSentence('sounds/ding.m4a');

        await delay(1000);

        if (round.order === this.totalRounds - 5) {
            await playSentence('sounds/5_rounds_remaining.mp3');
        }
        if (round.order === this.totalRounds - 1) {
            await playSentence('sounds/last_round.mp3');
        }
    }

    public async playAudioForTask(task: Task): Promise<void> {
        await playSentence(task.audioFile);
    }

    // on clicking on screen, it will say "It is red"
    // there will be only one option - the right one, but it won't say anything on selection
    public async onAnswer(round: Round, answerIndex: number): Promise<void> {
        // play audio
    }

    // next click will switch to the new exercise

    // there won't be any evaluation at the end
    public onEvaluation(metrics: SessionMetrics) {}

    public getIntroJSX(): ReactNode {
        return null;
    }
}
