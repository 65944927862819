import {Box} from '@mui/material';
import React from 'react';

export enum ShapeType {
    Circle = 'circle',
    Square = 'square',
    Rectangle = 'rectangle',
    Triangle = 'triangle',
    Diamond = 'diamond',
    Star = 'star',
    Heart = 'heart',
    Plus = 'plus',
    Arrow = 'arrow',
    Pentagon = 'pentagon',
}

type ShapeProps = {
    shape: ShapeType;
    color: string;
    backgroundColor?: string;
};

export function Shape({
    shape,
    backgroundColor = 'transparent',
    color,
}: ShapeProps) {
    return (
        <Box height="100%" width="100%" sx={{backgroundColor}}>
            {shape === ShapeType.Circle && (
                <svg width="100%" height="100%" viewBox="-100 -100 200 200">
                    <circle cx={0} cy={0} r={80} stroke={color} fill={color} />
                </svg>
            )}
            {shape === ShapeType.Pentagon && (
                <svg width="100%" height="100%" viewBox="-100 -100 200 200">
                    <polygon
                        points="0,-80 70,-30 50,60 -50,60 -70,-30"
                        stroke={color}
                        fill={color}
                    />
                </svg>
            )}
            {shape === ShapeType.Square && (
                <svg width="100%" height="100%" viewBox="-100 -100 200 200">
                    <rect
                        x={-80}
                        y={-80}
                        width={160}
                        height={160}
                        stroke={color}
                        fill={color}
                    />
                </svg>
            )}
            {shape === ShapeType.Rectangle && (
                <svg width="100%" height="100%" viewBox="-100 -100 200 200">
                    <rect
                        x={-80}
                        y={-40}
                        width={160}
                        height={80}
                        stroke={color}
                        fill={color}
                    />
                </svg>
            )}
            {shape === ShapeType.Triangle && (
                <svg width="100%" height="100%" viewBox="-100 -100 200 200">
                    <polygon
                        points="0,-80 80,80 -80,80"
                        stroke={color}
                        fill={color}
                    />
                </svg>
            )}
            {shape === ShapeType.Diamond && (
                <svg width="100%" height="100%" viewBox="-100 -100 200 200">
                    <polygon
                        points="0,-80 60,0 0,80 -60,0"
                        stroke={color}
                        fill={color}
                    />
                </svg>
            )}
            {shape === ShapeType.Star && (
                <svg width="100%" height="100%" viewBox="0 0 50 50">
                    {/* <polygon*/}
                    {/*    points="50,5 70,30 95,35 75,55 80,80 50,70 20,80 25,55 5,35 30,30"*/}
                    {/*    fill={color}*/}
                    {/* />*/}

                    <path
                        xmlns="http://www.w3.org/2000/svg"
                        fill={color}
                        d="m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z"
                    />
                </svg>
            )}
            {shape === ShapeType.Heart && (
                <svg width="100%" height="100%" viewBox="-20 -20 381 381">
                    <g transform="rotate(225,150,121)">
                        <path
                            d="M0 200 v-200 h200 a100,100 90 0,1 0,200 a100,100 90 0,1 -200,0 z"
                            fill={color}
                        />
                    </g>
                </svg>
            )}
            {shape === ShapeType.Plus && (
                <svg width="100%" height="100%" viewBox="-100 -100 200 200">
                    <line
                        x1={-80}
                        y1={0}
                        x2={80}
                        y2={0}
                        stroke={color}
                        strokeWidth={40}
                    />
                    <line
                        x1={0}
                        y1={-80}
                        x2={0}
                        y2={80}
                        stroke={color}
                        strokeWidth={40}
                    />
                </svg>
            )}
            {shape === ShapeType.Arrow && (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 100 100"
                    width="100%"
                    height="100%"
                >
                    <line
                        x1="20"
                        y1="50"
                        x2="80"
                        y2="50"
                        stroke={color}
                        strokeWidth="20"
                    />
                    <polygon points="90,50 60,20 60,80" fill={color} />
                </svg>
            )}
        </Box>
    );
}
