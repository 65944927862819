import {SxProps, Theme, Typography} from '@mui/material';
import {Box, Stack} from '@mui/material';
import type {PropsWithChildren, ReactElement} from 'react';
import React from 'react';
import {Outlet} from 'react-router-dom';

import {MainAppBar} from './MainAppBar/MainAppBar';

interface PageLayoutProps {}

export function PageLayout({
    children,
}: PropsWithChildren<PageLayoutProps>): ReactElement {
    return (
        <Stack sx={styles.layout}>
            <MainAppBar />

            <Box p={3} flex={1}>
                {children || <Outlet />}
            </Box>

            <Box sx={styles.footer}>
                <Typography variant="body2" display="inline-block">
                    Blindfolded &copy; 2023
                </Typography>
            </Box>
        </Stack>
    );
}

const styles = {
    layout: {
        margin: '0 auto',
        minHeight: '100vh',
    } as SxProps<Theme>,
    footer: {
        textAlign: 'center',
        padding: 2,
        color: theme => theme.palette.text.secondary,
        backgroundColor: 'rgba(0,0,0,0.5)',
    } as SxProps<Theme>,
};
