import { css } from '@emotion/css';

export const containers = {
  appTitle: css`
    margin-bottom: 10rem;
  `,
  app: css`
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 1em;
  `,
  narrow: css`
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  `,
  card: css`
    background: white;
    border-radius: 0.5em;
    box-shadow: rgba(0, 0, 0, 0.1) 0 3px 20px;
    padding: 1em;
  `,
  paper: css`
    padding: 1em;
  `,
};
