import React, {PropsWithChildren, ReactElement} from 'react';
import {useIsMobile} from '../../hooks/useIsMobile';
import {Card, CardContent, Stack, Typography} from '@mui/material';

export function LessonCardStack({
    children,
}: PropsWithChildren<{}>): ReactElement {
    const isMobile = useIsMobile();
    return <Stack gap={isMobile ? 0 : 2}>{children}</Stack>;
}

type LessonCardProps = {
    title?: string;
};

export function LessonCard({
    title,
    children,
}: PropsWithChildren<LessonCardProps>): ReactElement {
    const isMobile = useIsMobile();
    return (
        <Card
            variant="outlined"
            sx={{
                '& + &': {
                    marginTop: isMobile ? '-1px' : 0,
                },
                marginX: isMobile ? '-25px' : 0,
                borderRadius: isMobile ? 0 : null,
            }}
        >
            <CardContent sx={{p: isMobile ? 3 : null}}>
                {title && (
                    <Typography variant="h4" sx={{mb: 3}}>
                        {title}
                    </Typography>
                )}
                {children}
            </CardContent>
        </Card>
    );
}
