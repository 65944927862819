import {Box} from '@mui/material';
import type {PropsWithChildren, ReactElement} from 'react';
import React from 'react';

export function NarrowLayout({children}: PropsWithChildren<{}>): ReactElement {
    return <Box sx={styles.layout}>{children}</Box>;
}

const styles = {
    layout: {
        marginX: 'auto',
        maxWidth: {
            sm: '640px',
            xs: '100%',
        },
    },
};
