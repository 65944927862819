import React from 'react';
import {Route, Routes} from 'react-router-dom';

import {Level1} from './Level1';
import {Level2} from './Level2';
import {Level3} from './Level3';
import {Level4} from './Level4';

const levelComponents = [Level1, Level2, Level3, Level4];

export function LevelRoutes() {
    return (
        <Routes>
            {levelComponents.map((Component, index) => (
                <Route
                    path={`${index + 1}`}
                    key={index}
                    element={<Component />}
                />
            ))}
        </Routes>
    );
}
