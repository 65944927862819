import type {Dispatch, SetStateAction} from 'react';
import {useEffect, useState} from 'react';

const getStorageData = <T>(key: string, defaultValue: T) => {
    const savedItem = localStorage.getItem(key);
    return savedItem === null ? defaultValue : JSON.parse(savedItem);
};

export type UseLocalStorageResult<T> = [T, Dispatch<SetStateAction<T>>];

export function useLocalStorage<T>(
    key: string,
    defaultValue: T,
): UseLocalStorageResult<T> {
    const [value, setValue] = useState<T>(
        () => getStorageData(key, defaultValue) ?? defaultValue,
    );

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
}
