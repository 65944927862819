import type {Task} from '../../Pages/assignments';

export enum LessonType {
    Relax = 'relax',
    FullScreen = 'fullscreen',
    SplitScreen = 'splitscreen',
}

// export type Lesson = LessonPick1 | LessonPick1SplitScreen;
//
// export interface BaseLesson {
//     id?: string;
//     tasks: Task[];
// }
//
// export interface LessonPick1 extends BaseLesson {
//     type: LessonType.Pick1;
//     config: LessonConfigPick1;
// }
//
// export interface LessonPick1SplitScreen extends BaseLesson {
//     type: LessonType.Pick1SplitScreen;
//     config: LessonConfigPick1SplitScreen;
// }

export type LessonConfig =
    | RelaxLessonConfig
    | FullScreenLessonConfig
    | SplitScreenLessonConfig;

export interface BaseLessonConfig {
    prerequisites?: string[];

    tasks: string[];
}

export interface FullScreenLessonConfig extends BaseLessonConfig {
    type: LessonType.FullScreen;
    split: number;
}

export interface SplitScreenLessonConfig extends BaseLessonConfig {
    type: LessonType.SplitScreen;
    split: number;
}

export interface RelaxLessonConfig extends BaseLessonConfig {
    type: LessonType.Relax;
}
