import type { PropsWithChildren} from 'react';
import React, {
  createContext, useContext, useState,
} from 'react';

const fakeAuth = {
  signin(cb: () => void) {
    setTimeout(cb, 100); // fake async
  },
  signout(cb: () => void) {
    setTimeout(cb, 100);
  },
};

/** For more details on
 * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
 * refer to: https://usehooks.com/useAuth/
 */
const authContext = createContext<UseProvideAuthResult>({
  user: undefined,
  isAuthenticated: false,
  signout: () => { /* noop */
  },
  signin: () => { /* noop */
  },
});

const useProvideAuth = (): UseProvideAuthResult => {
  const [user, setUser] = useState<string>();
  const [isAuthenticated, setAuthenticated] = useState<boolean>(false);

  const signin = (cb: () => void) => fakeAuth.signin(() => {
    setUser('user');
    setAuthenticated(true);
    cb();
  });

  const signout = (cb: () => void) => fakeAuth.signout(() => {
    setUser(undefined);
    setAuthenticated(false);
    cb();
  });

  return {
    user,
    isAuthenticated,
    signin,
    signout,
  };
};

export function ProvideAuth({ children }: PropsWithChildren<{}>) {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

export const useAuth = (): UseProvideAuthResult => useContext(authContext);

interface UseProvideAuthResult {
  user: string | undefined;
  signin: (cb: () => void) => void;
  signout: (cb: () => void) => void;
  isAuthenticated: boolean;
}
