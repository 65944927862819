import {css} from '@emotion/css';
import LoadingButton from '@mui/lab/LoadingButton';
import {Card, CardContent, Typography} from '@mui/material';
import TextField from '@mui/material/TextField';
import React, {useCallback, useState} from 'react';
import {Link, useNavigate, useLocation} from 'react-router-dom';

import {containers} from '../../styles/containers';
import {typography} from '../../styles/typography';
import {useAuth} from '../../useAuth';

const styles = {
    formField: css`
        margin-bottom: 1em;
    `,
};

interface UseLoginResult {
    loading: boolean;
    login: (login: string, password: string) => void;
}

const useLogin = (): UseLoginResult => {
    const navigate = useNavigate();
    const location = useLocation();
    const auth = useAuth();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const {from} = (location.state as any) || {from: {pathname: '/'}};
    const [loading, setLoading] = useState<boolean>(false);

    const loginFn = useCallback(
        (login: string, password: string) => {
            setLoading(true);
            auth?.signin(() => {
                setLoading(false);
                navigate(from);
            });
        },
        [auth, history, from, navigate],
    );

    return {login: loginFn, loading};
};

const handleChange =
    (setter: (value: string) => void) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
        setter(event.target.value);
    };

export function LoginPage() {
    const {login, loading} = useLogin();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    return (
        <>
            <Typography variant="h1">Blindfolded</Typography>
            <div className={containers.narrow}>
                <Card>
                    <CardContent>
                        <h1>Sign in</h1>
                        <p>Enter the world of pure magic!</p>
                        <div className={styles.formField}>
                            <TextField
                                label="E-mail"
                                variant="outlined"
                                fullWidth
                                value={email}
                                onChange={handleChange(setEmail)}
                            />
                        </div>
                        <div className={styles.formField}>
                            <TextField
                                label="Password"
                                variant="outlined"
                                type="password"
                                fullWidth
                                value={password}
                                onChange={handleChange(setPassword)}
                            />
                        </div>
                        <LoadingButton
                            variant="contained"
                            loading={loading}
                            onClick={() => login(email, password)}
                            type="button"
                            fullWidth
                        >
                            Sign in
                        </LoadingButton>
                        <p>
                            Haven't registered with Blindfolded yet?
                            <Link to="/signup">Sign up here.</Link>
                        </p>
                    </CardContent>
                </Card>
            </div>
        </>
    );
}
