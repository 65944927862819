import {ShapeType} from '../Components/Common/Shape';
import type {LessonConfig} from '../Components/Levels/types';
import {LessonType} from '../Components/Levels/types';

export const LESSONS: Record<string, LessonConfig> = {
    '2001': {
        type: LessonType.Relax,
        tasks: ['white', 'black'],
    },
    '2002': {
        type: LessonType.Relax,
        tasks: ['white', 'green'],
    },
    '2003': {
        type: LessonType.Relax,
        tasks: ['yellow', 'red'],
    },
    '2004': {
        type: LessonType.Relax,
        tasks: ['yellow', 'blue'],
    },
    '2005': {
        type: LessonType.Relax,
        tasks: ['white', 'red', 'green', 'black'],
    },
    '2006': {
        type: LessonType.Relax,
        tasks: ['yellow', 'red', 'green', 'blue'],
    },
    '2007': {
        type: LessonType.Relax,
        tasks: ['white', 'yellow', 'red', 'green', 'blue', 'black'],
    },
    '2008': {
        type: LessonType.Relax,
        tasks: ['white', 'orange', 'red', 'green', 'blue', 'brown'],
    },
    '2009': {
        type: LessonType.Relax,
        tasks: ['red', 'orange', 'yellow', 'green', 'blue', 'purple'],
    },
    '2010': {
        type: LessonType.Relax,
        tasks: [
            'white',
            'yellow',
            'orange',
            'red',
            'green',
            'blue',
            'purple',
            'brown',
        ],
    },
    '20101': {
        type: LessonType.Relax,
        tasks: [
            'pink',
            'yellow',
            'orange',
            'red',
            'green',
            'blue',
            'purple',
            'black',
        ],
    },
    '20102': {
        type: LessonType.Relax,
        tasks: [
            'white',
            'pink',
            'yellow',
            'orange',
            'red',
            'green',
            'blue',
            'purple',
            'brown',
            'black',
        ],
    },

    '2011': {
        type: LessonType.FullScreen,
        tasks: ['white', 'black'],
        split: 2,
    },
    '2012': {
        type: LessonType.FullScreen,
        tasks: ['white', 'green'],
        split: 2,
    },
    '2013': {
        type: LessonType.FullScreen,
        tasks: ['white', 'orange'],
        split: 2,
    },
    '2014': {
        type: LessonType.FullScreen,
        tasks: ['yellow', 'red'],
        split: 2,
    },
    '2015': {
        type: LessonType.FullScreen,
        tasks: ['yellow', 'blue'],
        split: 2,
    },
    '2016': {
        type: LessonType.FullScreen,
        tasks: ['yellow', 'brown'],
        split: 2,
    },

    '2017': {
        type: LessonType.FullScreen,
        tasks: ['white', 'red', 'green', 'black'],
        split: 2,
    },
    '2018': {
        type: LessonType.FullScreen,
        tasks: ['yellow', 'red', 'green', 'blue'],
        split: 2,
    },
    '2019': {
        type: LessonType.FullScreen,
        tasks: ['white', 'orange', 'green', 'blue'],
        split: 2,
    },

    '2020': {
        type: LessonType.FullScreen,
        tasks: ['white', 'red', 'green', 'black'],
        split: 4,
    },
    '2021': {
        type: LessonType.FullScreen,
        tasks: ['yellow', 'red', 'green', 'blue'],
        split: 4,
    },
    '2022': {
        type: LessonType.FullScreen,
        tasks: ['white', 'orange', 'green', 'blue'],
        split: 4,
    },

    '2023': {
        type: LessonType.FullScreen,
        tasks: ['white', 'yellow', 'red', 'green', 'blue', 'black'],
        split: 6,
    },
    '2024': {
        type: LessonType.FullScreen,
        tasks: ['white', 'orange', 'red', 'green', 'blue', 'brown'],
        split: 6,
    },
    '2025': {
        type: LessonType.FullScreen,
        tasks: ['red', 'orange', 'yellow', 'green', 'blue', 'purple'],
        split: 6,
    },

    '2030': {
        type: LessonType.SplitScreen,
        split: 2,
        tasks: ['white', 'red', 'green', 'black'],
    },
    '2031': {
        type: LessonType.SplitScreen,
        split: 2,
        tasks: ['yellow', 'red', 'green', 'blue'],
    },
    '2032': {
        type: LessonType.SplitScreen,
        split: 2,
        tasks: ['white', 'orange', 'green', 'blue'],
    },
    '2033': {
        type: LessonType.SplitScreen,
        split: 6,
        tasks: ['white', 'yellow', 'red', 'green', 'blue', 'black'],
    },
    '2034': {
        type: LessonType.SplitScreen,
        split: 6,
        tasks: ['white', 'orange', 'red', 'green', 'blue', 'brown'],
    },
    '2035': {
        type: LessonType.SplitScreen,
        split: 6,
        tasks: ['red', 'orange', 'yellow', 'green', 'blue', 'purple'],
    },

    '3001': {
        type: LessonType.FullScreen,
        tasks: [ShapeType.Circle, ShapeType.Heart],
        split: 2,
    },
    '3002': {
        type: LessonType.FullScreen,
        tasks: [ShapeType.Triangle, ShapeType.Pentagon],
        split: 2,
    },
    '3003': {
        type: LessonType.FullScreen,
        tasks: [ShapeType.Square, ShapeType.Plus],
        split: 2,
    },
    '3004': {
        type: LessonType.FullScreen,
        tasks: [ShapeType.Diamond, ShapeType.Arrow],
        split: 2,
    },
    '3005': {
        type: LessonType.FullScreen,
        tasks: [ShapeType.Rectangle, ShapeType.Star],
        split: 2,
    },

    '3030': {
        type: LessonType.SplitScreen,
        split: 2,
        tasks: [
            ShapeType.Circle,
            ShapeType.Square,
            ShapeType.Triangle,
            ShapeType.Star,
        ],
    },
    '3031': {
        type: LessonType.SplitScreen,
        split: 2,
        tasks: [
            ShapeType.Circle,
            ShapeType.Plus,
            ShapeType.Triangle,
            ShapeType.Arrow,
        ],
    },
    '3032': {
        type: LessonType.SplitScreen,
        split: 2,
        tasks: [
            ShapeType.Diamond,
            ShapeType.Heart,
            ShapeType.Square,
            ShapeType.Plus,
        ],
    },
    '3033': {
        type: LessonType.SplitScreen,
        split: 2,
        tasks: [
            ShapeType.Square,
            ShapeType.Circle,
            ShapeType.Triangle,
            ShapeType.Diamond,
        ],
    },
    '3034': {
        type: LessonType.SplitScreen,
        split: 2,
        tasks: [
            ShapeType.Square,
            ShapeType.Plus,
            ShapeType.Triangle,
            ShapeType.Arrow,
        ],
    },
    '3035': {
        type: LessonType.SplitScreen,
        split: 2,
        tasks: [
            ShapeType.Heart,
            ShapeType.Star,
            ShapeType.Arrow,
            ShapeType.Plus,
        ],
    },
};
