import {ArrowBack} from '@mui/icons-material';
import {Box, Divider, IconButton, Stack, Typography} from '@mui/material';
import type {ReactElement} from 'react';
import React, {useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';

import {useAppContext} from '../../../contexts/AppContext';
import green from '../../../images/green.webp';
import {NarrowLayout} from '../../Common/NarrowLayout';
import {LessonCard, LessonCardStack} from '../LessonCard';
import {CustomPractice} from '../../CustomPractice/CustomPractice';
import {ALL_TASKS} from '../../../sessions/tasks';
import {TaskType} from '../../../Pages/assignments';

const NUMBERS = ALL_TASKS.filter(t => t.type === TaskType.Number);
const LETTERS = ALL_TASKS.filter(t => t.type === TaskType.Letter);

export function Level4(): ReactElement {
    const {setLastLevel} = useAppContext();
    const navigate = useNavigate();

    useEffect(() => {
        setLastLevel(3);
    }, [setLastLevel]);

    return (
        <NarrowLayout>
            <IconButton onClick={() => navigate('/training')} sx={{mb: 2}}>
                <ArrowBack />
            </IconButton>
            <Stack direction="row" gap={2} alignItems="center">
                <Box
                    display="inline-block"
                    component="img"
                    src={green}
                    alt="Green Blindfold"
                    height="5rem"
                    width="auto"
                />
                <Box>
                    <Typography variant="h2">Reading</Typography>
                    <Typography variant="subtitle2">
                        The Green Blindfold
                    </Typography>
                </Box>
            </Stack>

            <Divider />

            <Stack gap={4}>
                <Box>
                    <Typography variant="h3" gutterBottom>
                        Numbers
                    </Typography>

                    <LessonCardStack>
                        <LessonCard>
                            <CustomPractice name="number" tasks={NUMBERS} />
                        </LessonCard>
                    </LessonCardStack>
                </Box>

                <Box>
                    <Typography variant="h3" gutterBottom>
                        Letters
                    </Typography>

                    <LessonCardStack>
                        <LessonCard>
                            <CustomPractice name="letter" tasks={LETTERS} />
                        </LessonCard>
                    </LessonCardStack>
                </Box>
            </Stack>
        </NarrowLayout>
    );
}
