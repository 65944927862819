import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import {Box, Button, Stack, Typography} from '@mui/material';
import type {ReactElement} from 'react';
import React, {useEffect, useMemo} from 'react';
import {Link} from 'react-router-dom';

import type {SessionMetrics} from '../PracticeSession/types';
import {useAppContext} from '../../contexts/AppContext';
import {routeBuilder} from '../../utility/routeBuilder';
import {useLocalStorage} from '../../hooks/useLocalStorage';
import {NarrowLayout} from '../Common/NarrowLayout';
import {PageLayout} from '../Common/PageLayout';
import {useAnalytics} from 'use-analytics';

interface SessionEndProps {
    lessonId?: string;
    metrics: SessionMetrics;
    persist: boolean;
}

function usePersistSession(
    lessonId: string | undefined,
    session: SessionMetrics,
    persist: boolean,
) {
    const [, setSessionStorage] = useLocalStorage<SessionMetrics[]>(
        `lesson-${lessonId}.sessions`,
        [],
    );

    useEffect(() => {
        if (lessonId !== undefined && persist) {
            console.log('Persisting session #' + lessonId, session);
            setSessionStorage(value => [...(value ?? []), session]);
        }
    }, [lessonId, session, setSessionStorage, persist]);
}

export function SessionEnd({
    metrics,
    lessonId,
    persist,
}: SessionEndProps): ReactElement {
    const {track} = useAnalytics();

    useEffect(() => {
        track('session-end', {
            lessonId: lessonId,
            metrics: metrics,
            persist: persist,
        });
    }, [persist, lessonId, metrics, track]);

    usePersistSession(lessonId, metrics, persist);
    const {lastLevel} = useAppContext();

    const {summary, answers} = metrics;

    const {totalTasks, totalCorrect} = useMemo(() => {
        let correct = 0;
        let wrong = 0;

        for (const s of Object.values(summary)) {
            correct += s.correct;
            wrong += s.wrong;
        }

        return {
            totalTasks: correct + wrong,
            totalCorrect: correct,
        };
    }, [summary]);

    return (
        <PageLayout>
            <NarrowLayout>
                <Typography variant="h1" gutterBottom>
                    Congratulations!
                </Typography>
                <Box mb={4}>
                    <Stack
                        direction="row"
                        gap={1}
                        p={1}
                        mb={2}
                        justifyContent="center"
                    >
                        {answers.map((answer, i) => (
                            <Box key={i}>
                                {answer.correct ? (
                                    <CheckIcon color="success" />
                                ) : (
                                    <ClearIcon color="success" />
                                )}
                            </Box>
                        ))}
                    </Stack>
                    {metrics.score > 0 && (
                        <Box my={6}>
                            <Box
                                textAlign="center"
                                mb={1}
                                aria-label={`${metrics.score} stars`}
                            >
                                {Array(Math.ceil(metrics.score))
                                    .fill('')
                                    .map((_, i) => (
                                        <img
                                            src="star-2768.svg"
                                            alt="Star"
                                            key={i}
                                            style={{
                                                width: '3rem',
                                                height: '3rem',
                                            }}
                                        />
                                    ))}
                            </Box>
                            <Box textAlign="center">
                                {totalCorrect}/{totalTasks}
                            </Box>
                        </Box>
                    )}
                </Box>

                <Button
                    component={Link}
                    to={routeBuilder.level(lastLevel)}
                    variant="contained"
                >
                    Back to learning!
                </Button>
            </NarrowLayout>
        </PageLayout>
        // <Stack gap={2}>
        //     <TableContainer component={Paper}>
        //         <Table>
        //             <TableHead>
        //                 <TableRow>
        //                     <TableCell component="th">Task</TableCell>
        //                     <TableCell component="th" align="right">
        //                         Results
        //                     </TableCell>
        //                     <TableCell component="th" align="right">
        //                         %
        //                     </TableCell>
        //                 </TableRow>
        //             </TableHead>
        //             <TableBody>
        //                 {Object.values(summary).map(e => (
        //                     <TableRow key={e.task.id}>
        //                         <TableCell>
        //                             {e.task.label}
        //                             &nbsp;
        //                             {e.task.value && (
        //                                 <div
        //                                     className={styles.coloredBlock}
        //                                     style={{
        //                                         backgroundColor: e.task.value,
        //                                     }}
        //                                 />
        //                             )}
        //                         </TableCell>
        //                         <TableCell align="right">
        //                             {e.correct > 0 &&
        //                                 [...Array(e.correct)].map((x, i) => (
        //                                     <CheckIcon
        //                                         key={i}
        //                                         color="success"
        //                                         fontSize="small"
        //                                     />
        //                                 ))}
        //                             {e.wrong > 0 &&
        //                                 [...Array(e.wrong)].map((x, i) => (
        //                                     <ClearIcon
        //                                         key={i}
        //                                         color="error"
        //                                         fontSize="small"
        //                                     />
        //                                 ))}
        //                         </TableCell>
        //                         <TableCell align="right">
        //                             {e.correct + e.wrong === 0
        //                                 ? 0
        //                                 : (
        //                                       (e.correct /
        //                                           (e.correct + e.wrong)) *
        //                                       100
        //                                   ).toFixed()}
        //                             %
        //                         </TableCell>
        //                     </TableRow>
        //                 ))}
        //             </TableBody>
        //             <TableFooter>
        //                 <TableRow>
        //                     <TableCell>Total</TableCell>
        //                     <TableCell align="right">
        //                         {metrics.score > 0 &&
        //                             Array(metrics.score)
        //                                 .fill('')
        //                                 .map((_, i) => (
        //                                     <img
        //                                         src="star-2768.svg"
        //                                         key={i}
        //                                         style={{
        //                                             width: '1.3rem',
        //                                             height: '1.3rem',
        //                                         }}
        //                                     />
        //                                 ))}
        //                     </TableCell>
        //                     <TableCell align="right">
        //                         {totalTasks === 0
        //                             ? 0
        //                             : (
        //                                   (totalCorrect / totalTasks) *
        //                                   100
        //                               ).toFixed()}
        //                         %
        //                     </TableCell>
        //                 </TableRow>
        //             </TableFooter>
        //         </Table>
        //     </TableContainer>
        //     <Paper sx={{p: 1}}>
        //         <Stack direction="column" gap={1} p={1} mb={2}>
        //             {answers.map((answer, i) => (
        //                 <Box key={i}>
        //                     <Stack gap={1} direction="row" key={i}>
        //                         {answer.correct ? (
        //                             <CheckIcon color="success" />
        //                         ) : (
        //                             <ClearIcon color="success" />
        //                         )}
        //                         <Box>
        //                             {(
        //                                 (answer.end.getTime() -
        //                                     answer.start.getTime()) /
        //                                 1000
        //                             ).toFixed(1)}
        //                             s
        //                         </Box>
        //                     </Stack>
        //                 </Box>
        //             ))}
        //         </Stack>
        //         <Button component={Link} to="/" variant="contained">
        //             Back to learning!
        //         </Button>
        //     </Paper>
        // </Stack>
    );
}
