import {FullScreenLessonConfig, LessonType} from '../Levels/types';
import {Round} from '../../Pages/assignments';
import {playSentence} from '../../utility/playAudio';
import React, {
    ReactElement,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {Lesson, TOTAL_ROUNDS} from './Lesson';
import {shuffle} from '../../utility/shuffle';
import {LessonIntroProps} from './types';
import {Box, Stack, Typography} from '@mui/material';
import {PracticeRoundBoard} from '../PracticeSession/PracticeRoundBoard';
import {ScreenPreview} from '../ScreenPreview';
import CampaignIcon from '@mui/icons-material/Campaign';
import {playRightAnswer} from './playRightAnswer';

export class FullScreenLesson extends Lesson {
    constructor(
        public readonly config: FullScreenLessonConfig,
        id: string | undefined = undefined,
        public readonly totalRounds = TOTAL_ROUNDS,
    ) {
        super(LessonType.FullScreen, id, totalRounds, config.tasks);
    }

    public getRound(i: number): Round {
        if (i >= this.totalRounds) {
            throw new Error('No more rounds!');
        }

        return this.getFullScreenRound(i);
    }

    public getFullScreenRound(i: number): Round {
        const {split} = this.config;
        const randomPick = Math.trunc(Math.random() * split);

        return {
            lessonType: LessonType.FullScreen,
            order: i,
            answerIndex: randomPick,
            options:
                split == this.tasks.length
                    ? this.tasks
                    : shuffle([...this.tasks])
                          .slice(0, split)
                          .sort(
                              (a, b) =>
                                  (a?.sortingIndex ?? 0) -
                                  (b?.sortingIndex ?? 0),
                          ),
            tasks: this.tasks,
        };
    }

    public async onRoundStart(round: Round): Promise<void> {
        await super.onRoundStart(round);

        // for Pick1 lessons with 2 options, play the options one by one
        if (
            round.options.length < this.config.tasks.length &&
            round.options.length === 2
        ) {
            await playSentence(
                round.options[0].audioFile,
                100,
                'sounds/or.mp3',
                100,
                round.options[1].audioFile,
            );
        }
    }

    public async onAnswer(round: Round, answerIndex: number): Promise<void> {
        await playSentence(800);
        await playRightAnswer(round.options[round.answerIndex]);
    }

    public getIntroJSX(): ReactNode {
        return (
            <>
                <Typography paragraph>
                    Every round will show a random color/shape on the screen and
                    your task is to select the correct answer that matches the
                    displayed target. You will pick one either by tapping on the
                    corresponding section or pressing the associated number key
                    on your keyboard.
                </Typography>
                {this.config.split === 2 && this.config.tasks.length !== 2 && (
                    <Typography paragraph>
                        The round will start by announcing the names of two
                        colors / shapes / ..., e.g. "{this.tasks[0].label} or{' '}
                        {this.tasks[1].label}", which also matches the layout of
                        the sections on the screen.
                    </Typography>
                )}
                {this.config.split === this.config.tasks.length && (
                    <Typography paragraph>
                        Positions of sections on the screen can be memorized,
                        but you can also slide your finger across the screen of
                        your mobile device to find the right one with audio
                        clues.
                    </Typography>
                )}
                {this.screenPreviewJSX}
            </>
        );
    }

    public screenPreviewJSX = (<FullScreenIntro lesson={this} />);
}

function FullScreenIntro({
    lesson,
}: LessonIntroProps<FullScreenLesson>): ReactElement {
    const [counter, setCounter] = useState(0);

    const proceed = useCallback(() => {
        setCounter(c => (c + 1) % lesson.config.split);
    }, [lesson, lesson.config.split]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            proceed();
        }, 5000);
        return () => clearTimeout(timeout);
    }, [proceed, counter]);

    const round = useMemo(() => {
        return {
            lessonType: LessonType.FullScreen,
            order: counter,
            answerIndex: counter,

            options:
                typeof lesson.config.split === 'number' &&
                lesson.config.split == lesson.tasks.length
                    ? lesson.tasks
                    : shuffle([...lesson.tasks]).slice(0, lesson.config.split),
            tasks: lesson.tasks,
        };
    }, [lesson.tasks, lesson.config.split, counter]);

    return (
        <>
            {lesson.config.split === 2 && lesson.config.tasks.length !== 2 && (
                <Stack direction="row" gap={2} alignItems="center" mb={2}>
                    <CampaignIcon fontSize="large" />
                    <Box>
                        '{round.options[0].label}' or '{round.options[1].label}'
                    </Box>
                </Stack>
            )}
            <ScreenPreview>
                <PracticeRoundBoard
                    options={round.options}
                    hideControls={false}
                    hideLabels={false}
                    onAnswer={() => Promise.resolve(proceed())}
                    round={round}
                    totalRounds={lesson.totalRounds}
                    tutorial={true}
                />
            </ScreenPreview>
        </>
    );
}
