/* Initialize analytics & load plugins */
import {Analytics} from 'analytics';
import googleAnalytics from '@analytics/google-analytics';

export const analytics = Analytics({
    app: 'blindfolded',
    plugins: [
        googleAnalytics({
            measurementIds: ['G-DGV6F6KJ95'],
            enabled: process.env.NODE_ENV === 'production',
        }),
    ],
});
