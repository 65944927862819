import {Box, BoxProps, Typography} from '@mui/material';
import React from 'react';
import {WideLayout} from '../Common/WideLayout';
import {LevelTile} from '../LevelTile/LevelTile';
import {useLocalStorage} from '../../hooks/useLocalStorage';

import white from '../../images/white.webp';
import orange from '../../images/orange.webp';
import yellow from '../../images/yellow.webp';
import green from '../../images/green.webp';

export const LEVEL_DATA = [
    {
        title: 'Introduction',
        level: 1,
        buttonLabel: 'Prepare',
        imageSrc: white,
        description: (
            <>
                The white blindfold represents the first step in the journey
                towards mastering the art of inner sight. The focus of this step
                is to obtain a blindfold to practice with and to understand the
                purpose of this journey.
            </>
        ),
    },
    {
        title: 'Colors',
        level: 2,
        buttonLabel: 'Practice',
        imageSrc: yellow,
        description: (
            <>
                In the quest to develop the inner sight, the second step towards
                mastery is to obtain the yellow blindfold. To achieve this goal,
                the student must learn to recognize colors with their eyes
                covered.
            </>
        ),
    },
    {
        title: 'Shapes',
        level: 3,
        buttonLabel: 'Practice',
        imageSrc: orange,
        description: (
            <>
                The orange blindfold degree focuses on the recognition of basic
                shapes. To complete this level, the student must learn to
                recognize these shapes with their eyes closed. They must also
                learn to recognize these shapes in various orientations and
                sizes.
            </>
        ),
    },
    {
        title: 'Reading',
        level: 4,
        buttonLabel: 'Practice',
        imageSrc: green,
        description: (
            <>
                Once the student has mastered the recognition of colors and
                shapes with the mind's eye, they are now ready to learn to read
                with their eyes covered. The green blindfold degree focuses on
                the recognition of letters, numbers and words.
            </>
        ),
    },
];

type LevelTileProps = {
    index: number;
} & BoxProps;

/**
 * This is a helper component that allows us to render a `LevelTile` by its
 * index in the `LEVEL_DATA` array.
 */
export function LevelTileByIndex({index, ...boxProps}: LevelTileProps) {
    const levelData = LEVEL_DATA[index];

    return (
        <LevelTile
            title={levelData.title}
            level={levelData.level}
            buttonLabel={levelData.buttonLabel}
            imageSrc={levelData.imageSrc}
            {...boxProps}
        >
            {levelData.description}
        </LevelTile>
    );
}

export function Training() {
    const [level1Data] = useLocalStorage<Record<string, unknown>>(`level1`, {});

    return (
        <>
            <Typography variant="h2" component="div" align="center" mb={4}>
                Training
            </Typography>

            <WideLayout>
                <Box
                    sx={{
                        display: 'grid',
                        gap: 4,
                        gridTemplateColumns: {
                            lg: 'repeat(3, 1fr)',
                            // md: 'repeat(2, 1fr)',
                            sm: 'repeat(2, 1fr)',
                            xs: '1fr',
                        },
                    }}
                >
                    {Array(4)
                        .fill(null)
                        .map((_, i) => (
                            <LevelTileByIndex
                                key={LEVEL_DATA[i].level}
                                index={i}
                            />
                        ))}

                    <LevelTile title="Movement" level={5} disabled>
                        Coming soon...
                    </LevelTile>

                    <LevelTile title="Balance" level={6} disabled>
                        Coming soon...
                    </LevelTile>

                    <LevelTile title="Rear/side view" level={7} disabled>
                        Coming soon...
                    </LevelTile>

                    <LevelTile title="Remote vision" level={8} disabled>
                        Coming soon...
                    </LevelTile>

                    <LevelTile title="Time travel" level={9} disabled>
                        Coming soon...
                    </LevelTile>

                    {/* <PathStep title="Green" level={4} disabled>*/}
                    {/*    After achieving the Yellow Belt and mastering the*/}
                    {/*    recognition of colors with the mind's eye, the student is*/}
                    {/*    now ready to embark on the next stage of their journey*/}
                    {/*    towards inner sight. The Orange Blindfold curriculum focuses*/}
                    {/*    on the recognition of shapes, including triangles, squares,*/}
                    {/*    circles, and other geometrical figures. To achieve this*/}
                    {/*    goal, the student must first learn to visualize these shapes*/}
                    {/*    with their eyes closed. They must familiarize themselves*/}
                    {/*    with the basic shapes, their angles, and their properties.*/}
                    {/*    They must also learn to recognize these shapes in various*/}
                    {/*    orientations and sizes.*/}
                    {/* </PathStep>*/}

                    {/* <PathStep title="Blue" level="4" disabled>*/}
                    {/*    After achieving the Yellow Belt and*/}
                    {/*    mastering the recognition of colors with*/}
                    {/*    the mind's eye, the student is now ready*/}
                    {/*    to embark on the next stage of their*/}
                    {/*    journey towards inner sight. The Orange*/}
                    {/*    Blindfold curriculum focuses on the*/}
                    {/*    recognition of shapes, including*/}
                    {/*    triangles, squares, circles, and other*/}
                    {/*    geometrical figures. To achieve this*/}
                    {/*    goal, the student must first learn to*/}
                    {/*    visualize these shapes with their eyes*/}
                    {/*    closed. They must familiarize themselves*/}
                    {/*    with the basic shapes, their angles, and*/}
                    {/*    their properties. They must also learn*/}
                    {/*    to recognize these shapes in various*/}
                    {/*    orientations and sizes.*/}
                    {/* </PathStep>*/}

                    {/* <PathStep title="Purple" level="5" disabled>*/}
                    {/*    After achieving the Yellow Belt and*/}
                    {/*    mastering the recognition of colors with*/}
                    {/*    the mind's eye, the student is now ready*/}
                    {/*    to embark on the next stage of their*/}
                    {/*    journey towards inner sight. The Orange*/}
                    {/*    Blindfold curriculum focuses on the*/}
                    {/*    recognition of shapes, including*/}
                    {/*    triangles, squares, circles, and other*/}
                    {/*    geometrical figures. To achieve this*/}
                    {/*    goal, the student must first learn to*/}
                    {/*    visualize these shapes with their eyes*/}
                    {/*    closed. They must familiarize themselves*/}
                    {/*    with the basic shapes, their angles, and*/}
                    {/*    their properties. They must also learn*/}
                    {/*    to recognize these shapes in various*/}
                    {/*    orientations and sizes.*/}
                    {/* </PathStep>*/}

                    {/* <PathStep title="Brown" level="6" disabled>*/}
                    {/*    After achieving the Yellow Belt and*/}
                    {/*    mastering the recognition of colors with*/}
                    {/*    the mind's eye, the student is now ready*/}
                    {/*    to embark on the next stage of their*/}
                    {/*    journey towards inner sight. The Orange*/}
                    {/*    Blindfold curriculum focuses on the*/}
                    {/*    recognition of shapes, including*/}
                    {/*    triangles, squares, circles, and other*/}
                    {/*    geometrical figures. To achieve this*/}
                    {/*    goal, the student must first learn to*/}
                    {/*    visualize these shapes with their eyes*/}
                    {/*    closed. They must familiarize themselves*/}
                    {/*    with the basic shapes, their angles, and*/}
                    {/*    their properties. They must also learn*/}
                    {/*    to recognize these shapes in various*/}
                    {/*    orientations and sizes.*/}
                    {/* </PathStep>*/}

                    {/* <PathStep title="Red" level="7" disabled>*/}
                    {/*    After achieving the Yellow Belt and*/}
                    {/*    mastering the recognition of colors with*/}
                    {/*    the mind's eye, the student is now ready*/}
                    {/*    to embark on the next stage of their*/}
                    {/*    journey towards inner sight. The Orange*/}
                    {/*    Blindfold curriculum focuses on the*/}
                    {/*    recognition of shapes, including*/}
                    {/*    triangles, squares, circles, and other*/}
                    {/*    geometrical figures. To achieve this*/}
                    {/*    goal, the student must first learn to*/}
                    {/*    visualize these shapes with their eyes*/}
                    {/*    closed. They must familiarize themselves*/}
                    {/*    with the basic shapes, their angles, and*/}
                    {/*    their properties. They must also learn*/}
                    {/*    to recognize these shapes in various*/}
                    {/*    orientations and sizes.*/}
                    {/* </PathStep>*/}

                    {/* <PathStep title="Black" level="8" disabled>*/}
                    {/*    After achieving the Yellow Belt and*/}
                    {/*    mastering the recognition of colors with*/}
                    {/*    the mind's eye, the student is now ready*/}
                    {/*    to embark on the next stage of their*/}
                    {/*    journey towards inner sight. The Orange*/}
                    {/*    Blindfold curriculum focuses on the*/}
                    {/*    recognition of shapes, including*/}
                    {/*    triangles, squares, circles, and other*/}
                    {/*    geometrical figures. To achieve this*/}
                    {/*    goal, the student must first learn to*/}
                    {/*    visualize these shapes with their eyes*/}
                    {/*    closed. They must familiarize themselves*/}
                    {/*    with the basic shapes, their angles, and*/}
                    {/*    their properties. They must also learn*/}
                    {/*    to recognize these shapes in various*/}
                    {/*    orientations and sizes.*/}
                    {/* </PathStep>*/}
                </Box>
            </WideLayout>
        </>
    );
}
