import {Alert, Box, Button, Stack, Typography} from '@mui/material';
import type {PropsWithChildren, ReactElement} from 'react';
import React from 'react';
import {Link} from 'react-router-dom';
import {LEVEL_DATA, LevelTileByIndex} from '../../Components/Training/Training';
import {useIsMobile} from '../../hooks/useIsMobile';
import {NarrowLayout} from '../../Components/Common/NarrowLayout';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import {useAppContext} from '../../contexts/AppContext';

function TrainingSection() {
    const {lastLevel} = useAppContext();

    return (
        <NarrowLayout>
            <Typography variant="h2" align="center" mb={4}>
                Training
            </Typography>

            <LevelTileByIndex index={lastLevel - 1} sx={{mb: 4}} />

            <Button
                component={Link}
                variant="outlined"
                to="/training"
                sx={{
                    width: {xs: '100%', sm: 'auto'},
                }}
            >
                All levels ...
            </Button>
        </NarrowLayout>
    );
}

export function HomePage(): ReactElement {
    const isMobile = useIsMobile();

    return (
        <>
            <Hero>
                <Box textAlign="center">
                    <Box
                        component="img"
                        src="logo2.webp"
                        alt="Blindfolded logo"
                        maxWidth="100%"
                        width="auto"
                        maxHeight="200px"
                        height="auto"
                        mt={2}
                        mb={4}
                    />
                </Box>

                <NarrowLayout>
                    <Alert severity="warning">
                        This website is still under development and changes are
                        expected to occur. You'll probably lose your progress as
                        the app evolves, since it is currently only stored
                        locally on your device. Should you encounter any issues
                        or have ideas worth sharing, please reach out to me in
                        the{' '}
                        <a href="https://www.facebook.com/groups/223679303576781/">
                            Blindfolded.app Facebook group
                        </a>
                        . Thank you!
                    </Alert>

                    {isMobile ? (
                        <Stack justifyContent="center">
                            <Button
                                size="large"
                                component={Link}
                                variant="outlined"
                                to="/introduction"
                                sx={{m: 4}}
                            >
                                Introduction
                            </Button>
                        </Stack>
                    ) : (
                        <>
                            <Typography
                                variant="h2"
                                component="div"
                                align="center"
                                mt={4}
                                mb={4}
                            >
                                Welcome!
                            </Typography>
                            <Typography paragraph>
                                It it possible to see blindfolded. Yes, you read
                                that right. It is possible to "see" blindfolded
                                without using your physical eyes and what's even
                                better - children can learn this skill in a
                                matter of hours or days. For adults it takes
                                more time, but it is only a matter of training
                                and perseverance. This app was created to help
                                with that, so welcome and enjoy your stay!
                            </Typography>
                            <Button
                                component={Link}
                                variant="outlined"
                                to="/introduction"
                            >
                                Learn more...
                            </Button>
                        </>
                    )}
                </NarrowLayout>
            </Hero>

            <TrainingSection />
        </>
    );
}

function Hero({children}: PropsWithChildren<{}>) {
    return (
        <Stack
            sx={{
                mx: -3,
                mt: -3,
                mb: 4,
                p: 3,
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '100vh',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}
        >
            <Stack flex={1} justifyContent="center">
                {children}
            </Stack>
            <Box>
                <KeyboardDoubleArrowDownIcon color="disabled" />
            </Box>
        </Stack>
    );
}
