import {createTheme, SxProps, Theme} from '@mui/material';

export const bodyFontFamily = '"Merriweather", serif';
export const headingFontFamily = "'Source Serif Pro', serif";
export const characterFontFamily = "'Libre Baskerville', serif";

export const theme = createTheme({
    typography: {
        fontFamily: bodyFontFamily,
        // fontWeightLight: 300,
        body1: {
            // fontWeight: 'normal',
        },
        fontSize: 16,
        h1: {
            fontSize: '3rem',
            fontFamily: headingFontFamily,
            textAlign: 'center',
        },
        h2: {
            fontSize: '2.5rem',
            fontFamily: headingFontFamily,
        },
        h3: {
            fontSize: '2rem',
            fontFamily: headingFontFamily,
        },
        h4: {
            fontSize: '1.5rem',
            fontFamily: headingFontFamily,
        },
        button: {
            // fontSize: '1.2rem',
            fontFamily: bodyFontFamily,
        },
    },
    palette: {
        mode: 'dark',
        text: {
            primary: '#e7e7e7',
        },
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#e9e6e0',

            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            //     light: '#0066ff',
            main: '#0044ff',
            //     // dark: will be calculated from palette.secondary.main,
            //     contrastText: '#ffcc00',
        },
        background: {
            default: '#000',
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        // contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        // tonalOffset: 0.2,
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0 3px 20px',
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#111111',
                    backgroundImage: 'none',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'unset',
                    borderRadius: '999px',
                    // fontSize: '1.2rem',
                },
                sizeMedium: {
                    fontSize: '1.2rem',
                    paddingLeft: '2rem',
                    paddingRight: '2rem',
                },
                sizeLarge: {
                    padding: '1rem 2rem',
                    fontSize: '1.2rem',
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    textTransform: 'unset',
                    fontSize: '1.1rem',
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                colorTransparent: {
                    boxShadow: 'none',
                },
                colorPrimary: {
                    '& .MuiPaper-root .MuiAppBar-root': {
                        backgroundColor: '#000',
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontSize: '1rem',
                },
            },
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    fontSize: '1rem',
                    fontFamily: '"Merriweather", serif',
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                middle: {
                    marginTop: '32px',
                    marginBottom: '32px',
                },
                fullWidth: {
                    marginTop: '32px',
                    marginBottom: '32px',
                },
            },
        },
    },
    // components: {
    //     MuiLink: {
    //         defaultProps: {
    //             component: LinkBehavior,
    //         },
    //     },
    //     MuiButtonBase: {
    //         defaultProps: {
    //             LinkComponent: LinkBehavior,
    //         },
    //     },
    // },
});
