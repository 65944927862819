import type {LessonType} from '../Components/Levels/types';

export type Round = Pick1Round;

export interface BaseRound {
    order: number;
}

export interface Pick1Round extends BaseRound {
    lessonType: LessonType;
    /** All tasks that belong to parent lesson */
    tasks: Task[];
    options: Task[];
    answerIndex: number;
}

export enum TaskType {
    Color,
    Shape,
    Number,
    Letter,
    Word,
}

export interface Task {
    id: string;
    label: string;
    type: TaskType;
    value: string;
    audioFile: string;
    rightAnswerAudioFile?: string;
    sortingIndex?: number;
    key?: string;
}
