import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';

import {
    createHashRouter,
    createRoutesFromElements,
    Route,
    RouterProvider,
} from 'react-router-dom';

import {App} from './App';
import {PageLayout} from './Components/Common/PageLayout';
import {LevelRoutes} from './Components/Levels/LevelRoutes';
import {BlindfoldSessionPage} from './Pages/BlindfoldSessionPage/BlindfoldSessionPage';
import {HomePage} from './Pages/HomePage/HomePage';
import {LoginPage} from './Pages/LoginPage/LoginPage';
import reportWebVitals from './reportWebVitals';
import {Introduction} from './Pages/Introduction/Introduction';
import {Training} from './Components/Training/Training';
import {Warmup} from './Components/Warmup/Warmup';
import {ColoredWords} from './Components/Warmup/ColoredWords';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const router = createHashRouter(
    createRoutesFromElements(
        <Route path="/" element={<App />}>
            <Route element={<PageLayout />}>
                <Route path="/" element={<HomePage />} />
                <Route path="/introduction" element={<Introduction />} />
                <Route path="/training" element={<Training />} />
                <Route path="/warmup" element={<Warmup />} />
                <Route
                    path="/warmup/colored-words"
                    element={<ColoredWords />}
                />
                <Route path="/library" element={<Introduction />} />
                <Route path="/level/*" element={<LevelRoutes />} />
            </Route>
            <Route path="/session/:id" element={<BlindfoldSessionPage />} />
            <Route path="/session" element={<BlindfoldSessionPage />} />
            <Route path="/login" element={<LoginPage />} />

            {/* <Route path="/signup">*/}
            {/*    <SignUpPage />*/}
            {/* </Route>*/}
        </Route>,
    ),
);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <RouterProvider router={router} />,
);

// const root = ReactDOM.createRoot(
//     document.getElementById('root') as HTMLElement,
// );
// root.render(
//     // <React.StrictMode>
//     <App />,
//     // </React.StrictMode>
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
