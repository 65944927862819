import {ArrowBack} from '@mui/icons-material';
import {Box, Divider, IconButton, Stack, Typography} from '@mui/material';
import type {ReactElement} from 'react';
import React, {useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';

import {useAppContext} from '../../../contexts/AppContext';
import orange from '../../../images/orange.webp';
import {NarrowLayout} from '../../Common/NarrowLayout';
import {LessonCard, LessonCardStack} from '../LessonCard';
import {CustomPractice} from '../../CustomPractice/CustomPractice';
import {ALL_TASKS} from '../../../sessions/tasks';
import {TaskType} from '../../../Pages/assignments';

const SHAPES = ALL_TASKS.filter(t => t.type === TaskType.Shape);

export function Level3(): ReactElement {
    const {setLastLevel} = useAppContext();
    const navigate = useNavigate();

    useEffect(() => {
        setLastLevel(3);
    }, [setLastLevel]);

    return (
        <NarrowLayout>
            <IconButton onClick={() => navigate('/training')} sx={{mb: 2}}>
                <ArrowBack />
            </IconButton>
            <Stack direction="row" gap={2} alignItems="center">
                <Box
                    display="inline-block"
                    component="img"
                    src={orange}
                    alt="Orange Blindfold"
                    // maxWidth="100%"
                    // width="auto"
                    // maxHeight="300px"
                    height="5rem"
                    width="auto"
                />
                <Box>
                    <Typography variant="h2">Shapes</Typography>
                    <Typography variant="subtitle2">
                        The Orange Blindfold
                    </Typography>
                </Box>
            </Stack>

            <Divider />

            <Box>
                <Box mb={2}>
                    <Typography variant="h3">Practice</Typography>
                    <Typography variant="subtitle2" gutterBottom>
                        Choose colors to practice with.
                    </Typography>
                </Box>

                <LessonCardStack>
                    <LessonCard>
                        <CustomPractice name="shape" tasks={SHAPES} />
                    </LessonCard>
                </LessonCardStack>
            </Box>
        </NarrowLayout>
    );
}
