import CheckIcon from '@mui/icons-material/Check';
import {
    Box,
    BoxProps,
    Button,
    CardActionArea,
    CardContent,
    Typography,
} from '@mui/material';
import type {PropsWithChildren, ReactElement} from 'react';
import React from 'react';
import {Link} from 'react-router-dom';

import {useLocalStorage} from '../../hooks/useLocalStorage';

export type LevelTileProps = {
    title: string;
    level: number;
    disabled?: boolean;
    buttonLabel?: string;
    imageSrc?: string;
} & BoxProps;

export function LevelTile({
    title,
    disabled,
    level,
    children,
    imageSrc,
    buttonLabel = 'Practice',
    ...boxProps
}: PropsWithChildren<LevelTileProps>): ReactElement {
    const [levelData] = useLocalStorage<Record<string, unknown>>(
        `level${level}`,
        {},
    );
    const completed = levelData['completed'] as boolean;

    const url = `/level/${level}`;

    return (
        <Box {...boxProps}>
            <CardActionArea
                component={disabled ? 'div' : Link}
                to={url}
                disabled={disabled}
                sx={{
                    backgroundColor: 'rgba(255,255,255,0.05)',
                    position: 'relative',
                    filter: disabled ? 'grayscale(1)' : 'none',
                    borderRadius: 2,
                }}
            >
                <CardContent>
                    <Typography gutterBottom variant="h3" align="center" mb={4}>
                        {level}. {title}
                    </Typography>
                    <Box mb={2} textAlign="center" position="relative">
                        {imageSrc && (
                            <img
                                src={imageSrc}
                                alt={title}
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '300px',
                                    height: 'auto',
                                }}
                            />
                        )}
                        {completed && (
                            <CheckIcon
                                color="success"
                                sx={styles.completeIcon}
                            />
                        )}
                    </Box>
                    <Typography variant="body1">{children}</Typography>
                    {!disabled && (
                        <Box textAlign="center" mt={2}>
                            <Button variant="contained" color="primary">
                                {buttonLabel}
                            </Button>
                        </Box>
                    )}
                </CardContent>
                {disabled && <Box sx={[styles.fullCover, styles.disabled]} />}
            </CardActionArea>
        </Box>
    );
}

const styles = {
    completeIcon: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontSize: '15rem',
        filter: 'drop-shadow(0 5px 5px rgb(0 0 0 / 0.3))',
    },
    fullCover: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },
    disabled: {
        zIndex: 1,
        pointerEvents: 'none',
        backgroundImage:
            'linear-gradient(135deg, #ffffff 25%, #000000 25%, #000000 50%, #ffffff 50%, #ffffff 75%, #000000 75%, #000000 100%)',
        backgroundSize: '14.14px 14.14px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 2,
        opacity: 0.1,
    },
    disabledMessage: {
        display: 'inline-block',
        p: 1,
        borderRadius: 2,
        width: '100%',
        zIndex: 2,
        backgroundColor: 'rgba(0,0,0,0.9)',
        textAlign: 'center',
    },
};
