import {Clear} from '@mui/icons-material';
import {Box, IconButton} from '@mui/material';
import type {ReactElement} from 'react';
import React, {useCallback, useEffect, useRef, useState} from 'react';

import {PracticeRound} from './PracticeRound';
import type {AnswerRecord, SessionMetrics} from './types';
import {playAudio} from '../../utility/playAudio';
import {Lesson} from '../Lesson/Lesson';
import {Round} from '../../Pages/assignments';

export interface PracticeRoundsProps {
    lesson: Lesson;
    onQuit: () => void;
    onEnd: (metrics: SessionMetrics) => void;
    tutorial: boolean;
}

export function PracticeRounds({
    lesson,
    onQuit,
    onEnd,
    tutorial,
}: PracticeRoundsProps): ReactElement | null {
    const [round, setRound] = useState<Round>(() => lesson.getRound(0));
    const [roundStart, setRoundStart] = useState<Date>(new Date());
    const sessionRecordRef = useRef<SessionMetrics>({
        start: new Date(),
        end: new Date(),
        answers: [],
        summary: {},
        score: 0,
    });

    useEffect(() => {
        console.debug('Round start!', new Date());
        setRoundStart(new Date());

        (async () => {
            await lesson.onRoundStart(round);
        })();
    }, [round, onEnd]);

    const onAnswer = (correct: boolean, givenAnswer: number, time: Date) => {
        const correctAnswer = round.answerIndex;
        const answers: AnswerRecord[] = [
            ...sessionRecordRef.current.answers,
            {
                correct,
                givenAnswer,
                start: roundStart,
                end: time,
                taskId: round.options[correctAnswer].id,
            },
        ];

        const {summary} = sessionRecordRef.current;
        summary[correctAnswer] = summary?.[correctAnswer] ?? {
            correct: 0,
            wrong: 0,
            task: lesson.tasks[correctAnswer],
        };
        if (correct) {
            summary[correctAnswer].correct++;
        } else {
            summary[correctAnswer].wrong++;
        }

        const correctAnswers = answers.filter(a => a.correct).length;
        const score = Math.max(correctAnswers - answers.length / 2, 0);

        sessionRecordRef.current = {
            ...sessionRecordRef.current,
            answers,
            score,
        };
    };

    const onNext = useCallback(() => {
        const newRoundIndex = round.order + 1;
        if (newRoundIndex < lesson.totalRounds) {
            setRound(lesson.getRound(newRoundIndex));
        } else {
            onEnd(sessionRecordRef.current);
            void playAudio('sounds/congratulations.mp3');
            return;
        }
    }, [round]);

    return (
        <>
            <Box position="absolute" sx={{right: 0, top: 0, zIndex: 1}}>
                <IconButton onClick={onQuit} color="primary">
                    <Clear />
                </IconButton>
            </Box>

            <PracticeRound
                round={round}
                lesson={lesson}
                onAnswer={onAnswer}
                onNext={onNext}
                tutorial={tutorial}
            />
        </>
    );
}
