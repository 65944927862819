import React, {useMemo} from 'react';
import {useParams} from 'react-router-dom';

import {LESSONS} from '../../sessions/lessons';
import {useQueryParam} from '../../hooks/useQueryParam';
import {LessonType} from '../../Components/Levels/types';
import {PracticeSession} from '../../Components/PracticeSession/PracticeSession';
import {FullScreenLesson} from '../../Components/Lesson/FullScreenLesson';
import {Lesson} from '../../Components/Lesson/Lesson';
import {RelaxLesson} from '../../Components/Lesson/RelaxLesson';
import {SplitScreenLesson} from '../../Components/Lesson/SplitScreenLesson';

export function usePracticeSession(lessonId?: string): Lesson {
    const taskIdsParam = useQueryParam('tasks');
    const typeParam = useQueryParam('type') as LessonType;
    const splitParam = useQueryParam('split');
    const split = Number.parseInt(splitParam || '2');

    return useMemo<Lesson>(() => {
        if (taskIdsParam) {
            const taskIds = taskIdsParam?.split(',');
            if (typeParam === LessonType.FullScreen) {
                return new FullScreenLesson(
                    {
                        type: typeParam,
                        tasks: taskIds,
                        split: split,
                    },
                    undefined,
                );
            } else if (typeParam === LessonType.SplitScreen) {
                return new SplitScreenLesson(
                    {
                        type: typeParam,
                        tasks: taskIds,
                        split: split,
                    },
                    undefined,
                );
            } else if (typeParam === LessonType.Relax) {
                return new RelaxLesson(
                    {
                        type: typeParam,
                        tasks: taskIds,
                    },
                    undefined,
                );
            }
        }

        if (lessonId) {
            const config = LESSONS[lessonId];
            if (config.type === LessonType.FullScreen) {
                return new FullScreenLesson(config, lessonId);
            }
            if (config.type === LessonType.Relax) {
                return new RelaxLesson(config, lessonId);
            }
            if (config.type === LessonType.SplitScreen) {
                return new SplitScreenLesson(config, lessonId);
            }
        }

        throw new Error('Neither taskIds nor lessonId was defined!');
    }, [taskIdsParam, lessonId]);
}

export function BlindfoldSessionPage() {
    const {id} = useParams<{id: string}>();
    const startParam = useQueryParam('start');
    const lesson = usePracticeSession(id);

    return (
        <PracticeSession lesson={lesson} quickStart={startParam === 'true'} />
    );
}
