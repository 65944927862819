import React, {ReactNode, useCallback, useRef, useState} from 'react';
import {Box, SxProps, Theme} from '@mui/material';
import {useResizeObserverZ} from '../../hooks/useResizeObserverZ';
import {Task} from '../../Pages/assignments';
import {playAudio} from '../../utility/playAudio';

const HOLD_TIMEOUT = 3000;

interface MatrixSelectProps {
    tasks: Task[];
    onSelect: (i: number) => void;
    disabled: boolean;
    playAudioForTask?: (task: Task) => void;
    children: (task: Task, i: number) => ReactNode;
}

function normalize(value: number, min: number, max: number) {
    return Math.max(Math.min(value, max), min);
}

function getCoordinates(
    e: {clientX: number; clientY: number},
    target: HTMLElement,
    width: number,
    height: number,
) {
    const {clientX, clientY} = e;
    const xi = Math.floor(
        normalize((clientX / target.offsetWidth) * width, 0, width),
    );
    const yi = Math.floor(
        normalize((clientY / target.offsetHeight) * height, 0, height),
    );
    return {xi, yi};
}

/**
 * Matrix for selecting an option
 *
 * @param tasks
 * @param onSelect
 * @param children
 * @param disabled
 * @param playAudio
 * @constructor
 */
export function OptionMatrix({
    tasks,
    onSelect,
    children,
    disabled,
    playAudioForTask,
}: MatrixSelectProps) {
    const [selected, setSelected] = useState<{x: number; y: number} | null>(
        null,
    );
    const selectedRef = useRef<{x: number; y: number} | null>(null);

    const updateSelected = (xy: {x: number; y: number} | null) => {
        setSelected(xy);
        selectedRef.current = xy;
    };

    const [[width, height], setDimensions] = useState<[number, number]>([0, 0]);
    const containerRef = useRef<HTMLDivElement>(null);
    const holdTimeoutRef = useRef<number | null>(null);

    function startHoldTimeout() {
        holdTimeoutRef.current = window.setTimeout(() => {
            updateSelected(null);
            holdTimeoutRef.current = null;
            void playAudio('sounds/cancel.m4a');
        }, HOLD_TIMEOUT);
    }

    function clearHoldTimeout() {
        if (holdTimeoutRef.current !== null) {
            window.clearTimeout(holdTimeoutRef.current);
            holdTimeoutRef.current = null;
        }
    }

    const handleWindowResize = useCallback(() => {
        if (!containerRef.current) {
            return;
        }

        const [w, h] = calculateIdealDimensions(
            containerRef.current.clientWidth,
            containerRef.current.clientHeight,
            tasks.length,
        );
        setDimensions([w, h]);
    }, [tasks.length]);

    useResizeObserverZ(handleWindowResize);

    const handleTouchStart = (xi: number, yi: number) => {
        if (disabled) {
            return true;
        }

        const sel = selectedRef.current;
        if (sel?.x !== xi || sel?.y !== yi) {
            playSound(xi, yi);
            updateSelected({x: xi, y: yi});

            startHoldTimeout();
        }
    };

    const handleTouchMove = (xi: number, yi: number) => {
        if (disabled || !selectedRef.current) {
            return true;
        }

        const sel = selectedRef.current;
        if (sel?.x !== xi || sel?.y !== yi) {
            // Clear the timeout if it's still running
            clearHoldTimeout();
            startHoldTimeout();

            playSound(xi, yi);
            updateSelected({x: xi, y: yi});
        }
    };

    const handleTouchEnd = () => {
        if (disabled) {
            return true;
        }

        // Clear the timeout if it's still running
        clearHoldTimeout();

        const sel = selectedRef.current;
        if (sel) {
            onSelect(sel.y * width + sel.x);
            updateSelected(null);
        }
    };
    const playSound = (xi: number, yi: number) => {
        playAudioForTask?.(tasks[yi * width + xi]);
    };

    return (
        <Box
            ref={containerRef}
            sx={styles.grid(width, height, disabled)}
            onTouchStart={e => {
                // e.preventDefault();

                const {xi, yi} = getCoordinates(
                    e.touches[0],
                    e.currentTarget,
                    width,
                    height,
                );
                return handleTouchStart(xi, yi);
            }}
            onTouchMove={e => {
                const {xi, yi} = getCoordinates(
                    e.touches[0],
                    e.currentTarget,
                    width,
                    height,
                );
                return handleTouchMove(xi, yi);
            }}
            onTouchEnd={e => {
                e.preventDefault();

                return handleTouchEnd();
            }}
            onMouseDown={e => {
                const {xi, yi} = getCoordinates(
                    e,
                    e.currentTarget,
                    width,
                    height,
                );
                return handleTouchStart(xi, yi);
            }}
            onMouseMove={e => {
                // Only handle if left mouse button is pressed
                if ((e.buttons & 1) === 0) {
                    return;
                }

                const {xi, yi} = getCoordinates(
                    e,
                    e.currentTarget,
                    width,
                    height,
                );
                return handleTouchMove(xi, yi);
            }}
            onMouseUp={e => {
                return handleTouchEnd();
            }}
        >
            {Array(height)
                .fill(0)
                .map((_, yi) =>
                    Array(width)
                        .fill(0)
                        .map((_, xi) => (
                            <Box
                                key={`${xi}-${yi}`}
                                data-testid={`${xi}-${yi}`}
                                sx={styles.tile(disabled, xi, yi, selected)}
                                role="button"
                                aria-label={`Option ${xi + yi * width}`}
                            >
                                {children(
                                    tasks[yi * width + xi],
                                    yi * width + xi,
                                )}
                            </Box>
                        )),
                )}
        </Box>
    );
}

/**
 * Calculates the ideal dimensions for a grid of n elements
 * such that the size of the grid tiles is as close to square as possible
 * @param screenX width of the screen
 * @param screenY height of the screen
 * @param n number of elements
 */
export function calculateIdealDimensions(
    screenX: number,
    screenY: number,
    n: number,
): [number, number] {
    const screenRatio = screenX / screenY;
    let bestX = 1;
    let bestY = 1;
    let bestError = Infinity;
    let bestRatioError = Infinity;

    // Try all possible values for x and y
    for (let x = 1; x <= n; x++) {
        const y = Math.ceil(n / x);
        const error = Math.abs(x * y - n);

        const ratio = screenRatio * (y / x);
        const adjustedRatio = ratio >= 1 ? ratio : 1 / ratio;
        const ratioError = Math.abs(adjustedRatio - 1);

        // Check if this is a better solution than the current best one
        if (
            error < bestError ||
            (error === bestError && ratioError < bestRatioError)
        ) {
            bestX = x;
            bestY = y;
            bestError = error;
            bestRatioError = ratioError;
        }
    }

    return [bestX, bestY];
}

const styles = {
    grid: (width: number, height: number, disabled: boolean) =>
        ({
            position: 'absolute',
            top: 0,
            left: 0,
            display: 'grid',
            gridTemplateColumns: `repeat(${width}, 1fr)`,
            gridTemplateRows: `repeat(${height}, 1fr)`,
            width: '100%',
            height: '100%',
            touchAction: disabled ? 'none' : 'initial',
            userSelect: 'none',
            pointerEvents: disabled ? 'none' : 'initial',
        } as SxProps<Theme>),
    tile: (
        disabled: boolean,
        xi: number,
        yi: number,
        selected: null | {x: number; y: number},
    ) => ({
        width: `100%`,
        height: `100%`,
        cursor: disabled ? 'default' : 'pointer',
        borderLeft: xi === 0 ? 'none' : '1px dashed rgba(200,200,200,0.5)',
        borderTop: yi === 0 ? 'none' : '1px dashed rgba(200,200,200,0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        position: 'relative',
        backgroundColor:
            xi === selected?.x && yi === selected?.y
                ? 'rgba(255,255,255, 0.3)'
                : 'transparent',
        textShadow: '0 0 3px #000',
    }),
};
