import {ArrowBack} from '@mui/icons-material';
import {Divider, IconButton, Typography} from '@mui/material';
import React from 'react';
import {Link} from 'react-router-dom';

import {NarrowLayout} from '../../Components/Common/NarrowLayout';
import {YoutubeVideo} from '../../Components/Common/YoutubeVideo/YoutubeVideo';

export function Introduction() {
    return (
        <NarrowLayout>
            <IconButton component={Link} to="/">
                <ArrowBack />
            </IconButton>

            <Typography variant="h2" mt={4} mb={4}>
                Seeing blindfolded
            </Typography>
            <Typography paragraph>
                It it possible to see blindfolded. Yes, you read that right. It
                is possible to "see" blindfolded without using your physical
                eyes and what's even better - children can learn this skill in a
                matter of hours or days. For adults it takes more time, but it
                is only a matter of training and perseverance.
            </Typography>

            <Typography paragraph>
                Inner sight (Mind sight, Blindfolded sight, Third eye vision,
                etc.), the ability to "see" without the aid of physical eyes,
                has been a subject of fascination and skepticism for ages.
                Although it is considered fairly common in other parts of the
                world, skepticism toward it still persists in the West.
                Fortunately, in recent years, a growing number of awakened
                individuals have come to acknowledge that the inner vision is an
                authentic and replicable phenomenon.
            </Typography>

            <Typography paragraph>
                For those unfamiliar with the topic, the following videos should
                sufficiently demonstrate the capabilities of those well-versed
                in the art of inner sight.
            </Typography>

            <YoutubeVideo src="https://www.youtube-nocookie.com/embed/cS0EzNVDTqI" />
            <YoutubeVideo src="https://www.youtube-nocookie.com/embed/rC7xZmLcOeY" />
            <YoutubeVideo src="https://www.youtube-nocookie.com/embed/zuL-3ovm1-o" />
            <YoutubeVideo src="https://www.youtube-nocookie.com/embed/bq6NufaDR_w" />

            <Typography variant="h3" component="div" mt={4} mb={4}>
                The Challenge
            </Typography>

            <Typography paragraph>
                The cultivation of inner sight is no small task, particularly
                for adults who often require additional effort to achieve
                mastery. However, children possess a natural aptitude for this
                skill, owing to their playful approach to learning. The
                brainwave frequency of children operates differently from that
                of adults, with their predominant waves residing in the alpha
                and theta ranges, making it easier for them to tap into their
                intuition and creativity. Furthermore, children tend to employ
                the right hemisphere of their brain more frequently than adults,
                which is responsible for insight, creativity, and intuition.
                Overly serious practice can activate the left hemisphere, which
                hampers access to psychic abilities. Therefore, practitioners
                must find a balance between playfulness and commitment,
                cultivating curiosity, wonder, and joy throughout the practice
                to fully develop their inner sight abilities. Regardless of age
                or experience, adopting a playful approach to learning can help
                to activate the right hemisphere and unleash one's complete
                potential.
            </Typography>

            <Typography variant="h3" component="div" mt={4} mb={4}>
                The Doubt
            </Typography>
            <Typography paragraph>
                The skepticism surrounding the ability of children to
                demonstrate inner sight under laboratory conditions has been
                used to fuel doubt and disbelief in the public's eyes. Some
                skeptics posit that children's inability to demonstrate inner
                sight in these conditions suggests that the ability is not real
                or only the result of suggestion or wishful thinking. However,
                it is crucial to note that perceiving without the aid of
                physical eyes is a complex skill that requires proper conditions
                for development. Stressful laboratory conditions may not be
                conducive to unlocking one's inner sight, which can hinder one's
                ability to demonstrate the skill. Therefore, while it is
                understandable that skepticism may arise from failures to
                demonstrate inner sight under certain conditions, it is vital to
                approach the phenomenon with an open mind and a willingness to
                explore all possible explanations for the observed phenomena.
            </Typography>
            <Typography paragraph>
                Common argument of skeptics is that children are peeking through
                the mask, which after my personal experimentation with
                Mindfolds&reg; (a brand of meditation masks, which is commonly
                used in the blindfolded experiments), is indeed possible. The
                Mindfold&reg; mask can be too big for children (especially for
                children with smaller heads) and leaves a sizeable gap between
                the nose and mask. For adults however, they fit very well and
                are a great tool for practice.
            </Typography>

            <Typography variant="h3" component="div" mt={4} mb={4}>
                The Reward
            </Typography>

            <Typography paragraph>
                For those who are open to developing inner sight, a gateway to
                the world of psychic abilities awaits. Inner sight enables the
                practitioner to bridge the gap between the material and psychic
                worlds, providing real-time feedback during training. It is
                worth noting that the journey towards developing inner sight
                necessitates discipline and dedication. Nevertheless, unlocking
                this ability can have profound benefits, both for personal
                growth and the advancement of our society.
            </Typography>

            <Typography variant="h3" component="div" mt={4} mb={4}>
                More Information
            </Typography>

            <Typography paragraph />

            <Typography paragraph>
                There are many excellent resources available online where you
                can find more information about inner sight. Here are some of
                the best resources to find information and support:
                <ul>
                    <li>
                        <a href="https://www.facebook.com/groups/2485376505035437">
                            Rob Freeman's Facebook Group
                        </a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/SeeingBlindfoldedPracticeRobFreeman">
                            Rob Freeman's Youtube Channel
                        </a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/groups/2320198044686295">
                            Wendy Gallant's Facebook Group
                        </a>
                    </li>
                    <li>
                        <a href="https://youtube.com/channel/UCmp-mwCxiG4J-6QGrzKQ6cg">
                            Wendy Gallant's Youtube Channel
                        </a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/@SeanMcNamaraandMindPossible">
                            Sean McNamara's Youtube Channel
                        </a>
                        , particularly the{' '}
                        <a href="https://www.youtube.com/watch?v=TRWQ7eK3OFI&list=PLtIZw9cimimRW99OxMo1amXed28_5rIke">
                            MindSight playlist
                        </a>
                    </li>
                    <li>
                        Robert Smith's Website{' '}
                        <a href="https://blindfoldedsight.com/">
                            BlindfoldedSight.com
                        </a>
                        ; check out Robert's book that he posted recently in Rob
                        Freeman's Facebook group:
                        <a href="https://www.facebook.com/groups/2485376505035437/posts/3386026471637098">
                            Blindfolded Sight the Year Long Course
                        </a>
                    </li>
                </ul>
            </Typography>

            <Divider variant="middle" />

            <Typography paragraph>
                The Blindfolded application was conceived to help my children
                and me develop the skill of inner sight. As my son is practicing
                martial arts, I used the traditional belt color system as a
                metaphor for the different levels of inner sight skill that
                anyone can follow. The journey commences with the white
                blindfold and culminates with the black blindfold. The journey
                is the goal, so take it as seriously as you desire, but bear in
                mind that it will not work unless you enjoy yourself along the
                way. Therefore, be welcome, and please have fun while
                practicing!
            </Typography>
        </NarrowLayout>
    );
}
