import {LessonType, SplitScreenLessonConfig} from '../Levels/types';
import {Round, Task} from '../../Pages/assignments';
import {playAudio, playSentence} from '../../utility/playAudio';
import React, {
    ReactElement,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {Lesson, TOTAL_ROUNDS} from './Lesson';
import {shuffle} from '../../utility/shuffle';
import {LessonIntroProps} from './types';
import {ScreenPreview} from '../ScreenPreview';
import {PracticeRoundBoard} from '../PracticeSession/PracticeRoundBoard';
import CampaignIcon from '@mui/icons-material/Campaign';
import {Box, Stack, Typography} from '@mui/material';

export class SplitScreenLesson extends Lesson {
    constructor(
        public readonly config: SplitScreenLessonConfig,
        id: string | undefined = undefined,
        public readonly totalRounds = TOTAL_ROUNDS,
    ) {
        super(LessonType.SplitScreen, id, totalRounds, config.tasks);
    }

    public getRound(i: number): Round {
        if (i >= this.totalRounds) {
            throw new Error('No more rounds!');
        }

        return this.getSplitScreenRound(i);
    }

    public getSplitScreenRound(i: number): Round {
        const {split} = this.config;
        const randomPick = Math.trunc(Math.random() * split);

        return {
            lessonType: LessonType.SplitScreen,
            order: i,
            answerIndex: randomPick,
            options: shuffle([...this.tasks]).slice(0, split),
            tasks: this.tasks,
        };
    }

    public async onRoundStart(round: Round): Promise<void> {
        await super.onRoundStart(round);

        await playSentence(
            'sounds/select.mp3',
            round.options[round.answerIndex].audioFile,
        );
    }

    public async onAnswer(round: Round, answerIndex: number): Promise<void> {
        await playAudio(round.options[answerIndex].audioFile);
    }

    public async playAudioForTask(task: Task): Promise<void> {
        // noop;
    }

    public getIntroJSX(): ReactNode {
        return (
            <>
                <Typography paragraph>
                    You will be shown several tiles with colors/shapes/.. on
                    them and you'll be tasked to select the one that matches the
                    audio announcement at the beginning of the round.
                </Typography>
                {this.screenPreviewJSX}
            </>
        );
    }

    public screenPreviewJSX = (<SplitScreenIntro lesson={this} />);
}

function SplitScreenIntro({
    lesson,
}: LessonIntroProps<SplitScreenLesson>): ReactElement {
    const [counter, setCounter] = useState(0);

    const proceed = useCallback(() => {
        setCounter(c => (c + 1) % lesson.config.split);
    }, [lesson.config.split]);

    useEffect(() => {
        setCounter(0);
    }, [lesson.tasks, lesson.config.split]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            proceed();
        }, 5000);
        return () => clearTimeout(timeout);
    }, [proceed]);

    const round = useMemo(() => {
        return {
            lessonType: LessonType.SplitScreen,
            order: counter,
            answerIndex: counter,
            options: shuffle([...lesson.tasks]).slice(0, lesson.config.split),
            tasks: lesson.tasks,
        };
    }, [lesson.tasks, lesson.config.split, counter]);

    return (
        <>
            <Stack direction="row" gap={2} alignItems="center" mb={2}>
                <CampaignIcon fontSize="large" />
                <Box>Select '{round.options[round.answerIndex]?.label}'</Box>
            </Stack>
            <ScreenPreview>
                <PracticeRoundBoard
                    options={round.options}
                    hideControls={false}
                    hideLabels={false}
                    onAnswer={() => Promise.resolve(proceed())}
                    round={round}
                    totalRounds={lesson.totalRounds}
                    tutorial={true}
                />
            </ScreenPreview>
        </>
    );
}
