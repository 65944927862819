import MenuIcon from '@mui/icons-material/Menu';
import {
    AppBar,
    Box,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Stack,
} from '@mui/material';
import type {ReactElement} from 'react';
import React, {useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {LEVEL_DATA} from '../../Training/Training';
import HomeIcon from '@mui/icons-material/Home';

interface MainAppBarProps {
    absolute?: boolean;
}

export function MainAppBar({absolute = true}: MainAppBarProps): ReactElement {
    const [open, setOpen] = useState(false);
    const appBarRef = useRef<HTMLDivElement>(null);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <>
            <AppBar
                ref={appBarRef}
                position={absolute ? 'sticky' : 'static'}
                sx={{py: 1, px: 3, color: 'white'}}
                color="primary"
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    gap={1}
                >
                    <IconButton
                        color="inherit"
                        aria-label="Navigation"
                        edge="end"
                        onClick={handleDrawerOpen}
                    >
                        <MenuIcon />
                    </IconButton>
                </Stack>
            </AppBar>

            <Drawer
                open={open}
                onClose={handleDrawerClose}
                anchor="left"
                container={appBarRef.current}
            >
                <Box
                    sx={{width: '250px'}}
                    role="presentation"
                    component="nav"
                    onClick={handleDrawerClose}
                    onKeyDown={handleDrawerClose}
                >
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton component={Link} to="/">
                                <ListItemIcon>
                                    <HomeIcon />
                                </ListItemIcon>
                                <ListItemText primary="Home" />
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <Divider variant="fullWidth" sx={{my: 0}} />
                    <ListSubheader>Training</ListSubheader>
                    <List>
                        {Array(4)
                            .fill(null)
                            .map((_, i) => (
                                <ListItem disablePadding key={i}>
                                    <ListItemButton
                                        component={Link}
                                        to={`/level/${i + 1}`}
                                    >
                                        <ListItemText
                                            primary={`${i + 1}. ${
                                                LEVEL_DATA[i].title
                                            }`}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                    </List>
                </Box>
            </Drawer>
        </>
    );
}
