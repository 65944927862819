import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

type WakeLockType = 'screen';

/**
 * Represents a lock on the screen that prevents the device from dimming or locking the screen.
 */
interface WakeLockSentinel extends EventTarget {
    /** Whether the WakeLockSentinel's handle has been released. */
    readonly released: boolean;
    /** The WakeLockSentinel's wake lock type. */
    readonly type: WakeLockType;

    /** Releases the WakeLockSentinel's lock on the screen. */
    release(): Promise<undefined>;

    onrelease: ((this: WakeLockSentinel, ev: Event) => unknown) | null;
}

/**
 * Allows a document to acquire a screen wake lock.
 */
interface WakeLock {
    request(type: WakeLockType): Promise<WakeLockSentinel>;
}

/**
 * Represents the properties returned by useWakeLock.
 */
interface WakeLockProperties {
    isSupported: boolean;
    request: () => Promise<void>;
    release: () => void;
}

/**
 * Returns a WakeLockProperties object that can be used to request/release a screen wake lock.
 * Only works in secure contexts (https) or on localhost.
 */
export function useWakeLock(): WakeLockProperties {
    const [wakeLock, setWakeLock] = useState<WakeLock | null>(null);
    const wakeLockSentinelRef = useRef<WakeLockSentinel | null>(null);

    useEffect(() => {
        if (!('wakeLock' in navigator)) {
            return;
        }

        setWakeLock(navigator.wakeLock);
    }, []);

    const request = useCallback(async () => {
        if (wakeLock) {
            try {
                wakeLockSentinelRef.current = await wakeLock.request('screen');
                console.debug('WakeLock activated.');
            } catch (err) {
                console.error(err);
            }
        }
    }, [wakeLock]);

    const release = useCallback(async () => {
        if (wakeLockSentinelRef.current) {
            await wakeLockSentinelRef.current.release();
            wakeLockSentinelRef.current = null;
            console.debug('WakeLock released.');
        }
    }, []);

    return useMemo(() => {
        return {
            isSupported: wakeLock !== null,
            request,
            release,
        };
    }, [wakeLock, request, release]);
}
