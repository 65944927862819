import {useCallback, useEffect} from 'react';

export type Size = {
    width: number;
    height: number;
};

/**
 * Hook to observe screen size changes. The callback will be called with the new screen size.
 * The callback reference should be stable.
 */
export function useResizeObserverZ(
    callback: (size: Size) => void,
    element: Window | HTMLElement | null = window,
): void {
    const handleResize = useCallback(() => {
        const size: Size = {
            width:
                element instanceof Window
                    ? element.innerWidth
                    : element?.offsetWidth ?? 0,
            height:
                element instanceof Window
                    ? element.innerHeight
                    : element?.offsetHeight ?? 0,
        };
        callback(size);
    }, [element, callback]);

    useEffect(() => {
        handleResize();
    }, [handleResize]);

    useEffect(() => {
        element?.addEventListener('resize', handleResize);

        return () => {
            element?.removeEventListener('resize', handleResize);
        };
    }, [element, callback]);
}
