import React, {PropsWithChildren, ReactElement} from 'react';
import {Box} from '@mui/material';
import {Ribbon} from './Ribbon';

export function ScreenPreview({children}: PropsWithChildren<{}>): ReactElement {
    return (
        <Box
            position="relative"
            sx={{
                border: '1px solid white',
                borderRadius: '2px',
                aspectRatio: '16 / 9',
                // aspectRatio: `${Math.max(
                //     window.innerWidth,
                //     window.innerHeight,
                // )} / ${Math.min(window.innerWidth, window.innerHeight)}`,
                maxHeight: '400px',
                // aspectRatio: `16 / 9`,
                overflow: 'hidden',
            }}
        >
            <Ribbon label="Preview" />
            {children}
        </Box>
    );
}
