import {cx} from '@emotion/css';
import {Box} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import type {ReactElement} from 'react';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {CSSTransition} from 'react-transition-group';
import {styles} from './styles';
import type {Round} from '../../Pages/assignments';
import {useEventListener} from '../../Pages/useEventListener';
import {PracticeRoundBoard} from './PracticeRoundBoard';
import {Lesson} from '../Lesson/Lesson';

interface PracticeRoundProps {
    lesson: Lesson;
    round: Round;
    onAnswer: (correct: boolean, givenAnswer: number, time: Date) => void;
    onNext: () => void;
    tutorial: boolean;
}

export function PracticeRound({
    lesson,
    round,
    onAnswer,
    onNext,
    tutorial,
}: PracticeRoundProps): ReactElement {
    const [answering, setAnsweringImpl] = useState<boolean>(false);
    const answeringRef = useRef<boolean>(false);
    const [answered, setAnswered] = useState<boolean>(false);
    const loadingIndicatorRef = useRef(null);

    const options = round.options;

    useEffect(() => {
        setAnswered(false);
    }, [round]);

    const setAnswering = (value: boolean) => {
        answeringRef.current = value;
        setAnsweringImpl(value);
    };

    const submitAnswer = useCallback(
        async (answer: number) => {
            if (answer >= options.length) {
                return;
            }
            setAnswering(true);
            const answerTime = new Date();
            console.debug('Answer time:', answerTime);

            const correct = answer === round.answerIndex;

            try {
                await lesson.onAnswer(round, answer);
            } catch (e) {
                console.error('Error playing audio:', e);
            }

            onAnswer(correct, answer, answerTime);

            setAnswered(true);
            setAnswering(false);
        },
        [round, onAnswer, options, lesson],
    );

    const buttonAnswer = useCallback(
        async (answer: number) => {
            await submitAnswer(answer);
        },
        [submitAnswer],
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    // const voiceAnswer = useCallback(
    //     debounce(async (vAnswer: string) => {
    //         if (answeringRef.current || answered) {
    //             return;
    //         }
    //
    //         const answer = tasks.findIndex(c => c.voice === vAnswer);
    //         console.log('Voice answer:', vAnswer, answer);
    //         if (answer < 0) {
    //             return;
    //         }
    //
    //         await submitAnswer(answer);
    //     }, 300),
    //     [submitAnswer, answered],
    // );

    // useWebSpeechApi(!answering, voiceAnswer);

    const keyboardAnswer = useCallback(
        async (answer: number) => {
            if (answeringRef.current || answered) {
                return;
            }
            await lesson.playAudioForTask(options[answer]);
            await submitAnswer(answer);
        },
        [submitAnswer, answered],
    );

    const onKeyDown = useCallback(
        async (key: string) => {
            console.debug('Pressed key:', key);
            if (answered) {
                if (key === 'Enter' || key === ' ') {
                    onNext();
                }
                return;
            }

            const match = round.options.findIndex(t => t.key === key);
            if (match >= 0) {
                await keyboardAnswer(match);
            } else {
                const parsed = Number.parseInt(key);
                if (
                    Number.isNaN(parsed) ||
                    parsed < 1 ||
                    parsed > round.options.length
                ) {
                    return;
                }
                await keyboardAnswer(parsed - 1);
            }
        },
        [answered, onNext, keyboardAnswer, round.options],
    );

    const nextRound = useCallback(() => {
        if (answered) {
            onNext();
        }
    }, [answered, onNext]);

    useEventListener('keydown', event =>
        onKeyDown((event as KeyboardEvent).key),
    );

    return (
        <Box
            sx={[styles.fullScreen, answered ? {} : {touchAction: 'none'}]}
            onClick={nextRound}
        >
            <PracticeRoundBoard
                options={options}
                hideControls={answering || answered}
                hideLabels={!tutorial}
                onAnswer={buttonAnswer}
                round={round}
                totalRounds={lesson.totalRounds}
                tutorial={tutorial && !(answering || answered)}
                playAudio={task => lesson.playAudioForTask(task)}
            />
            <CSSTransition
                in={answering}
                classNames="fade"
                nodeRef={loadingIndicatorRef}
                unmountOnExit
                addEndListener={() => {}}
            >
                <Box
                    className={cx(styles.centered, styles.noInteraction)}
                    ref={loadingIndicatorRef}
                >
                    <Box
                        className={cx(
                            styles.answeringProgressContainer,
                            styles.withBackground,
                        )}
                    >
                        <CircularProgress
                            color="inherit"
                            sx={{display: 'block'}}
                        />
                    </Box>
                </Box>
            </CSSTransition>
            {answered && (
                <Box className={`${styles.bottom} ${styles.withBackground}`}>
                    Press ENTER/SPACE or click anywhere ...
                </Box>
            )}
        </Box>
    );
}
