import React, {useMemo} from 'react';
import {
    Alert,
    Box,
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Slider,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography,
} from '@mui/material';
import {TaskSelector} from '../TaskSelector/TaskSelector';
import {LessonType} from '../Levels/types';
import {Link} from 'react-router-dom';
import {useLocalStorage} from '../../hooks/useLocalStorage';
import {FullScreenLesson} from '../Lesson/FullScreenLesson';
import {Lesson} from '../Lesson/Lesson';
import {SplitScreenLesson} from '../Lesson/SplitScreenLesson';
import {RelaxLesson} from '../Lesson/RelaxLesson';
import {ScreenPreview} from '../ScreenPreview';
import {Task} from '../../Pages/assignments';
import ForwardSharpIcon from '@mui/icons-material/ForwardSharp';
import {capitalize} from 'lodash';

type CustomPracticeProps = {
    name: string;
    tasks: Task[];
};

export function CustomPractice({name, tasks}: CustomPracticeProps) {
    const [customTasks, setCustomTasks] = useLocalStorage<string[]>(
        `customPractice-${name}-tasks`,
        [...tasks.map(task => task.id)],
    );
    const [type, setType] = useLocalStorage<LessonType>(
        `customPractice-${name}-type`,
        LessonType.FullScreen,
    );
    const [optionPairs, setReducedSplit] = useLocalStorage<boolean>(
        `customPractice-${name}-reducedSplit`,
        false,
    );
    const [customSplit, setCustomSplit] = useLocalStorage<number>(
        `customPractice-${name}-customSplit`,
        customTasks.length,
    );
    const [practice, setPractice] = useLocalStorage<boolean>(
        `customPractice-${name}-relax`,
        false,
    );

    const consolidatedType = practice ? LessonType.Relax : type;
    const split =
        consolidatedType === LessonType.FullScreen
            ? optionPairs
                ? 2
                : customTasks.length
            : Math.max(2, Math.min(customTasks.length, customSplit));

    const lesson = useMemo<Lesson | null>(() => {
        if (customTasks.length < 2) {
            return null;
        }

        if (consolidatedType === LessonType.Relax) {
            return new RelaxLesson({
                tasks: customTasks,
                type: LessonType.Relax,
            });
        }
        if (consolidatedType === LessonType.FullScreen) {
            return new FullScreenLesson({
                tasks: customTasks,
                type: LessonType.FullScreen,
                split,
            });
        }
        if (consolidatedType === LessonType.SplitScreen) {
            return new SplitScreenLesson({
                tasks: customTasks,
                type: LessonType.SplitScreen,
                split,
            });
        }

        return null;
    }, [consolidatedType, practice, customTasks, split]);

    const lessonUrl = `/session?type=${consolidatedType}&tasks=${customTasks.join(
        ',',
    )}&split=${split}`;
    return (
        <Stack gap={2}>
            <TaskSelector
                value={customTasks}
                onChange={setCustomTasks}
                tasks={tasks}
            />

            <ToggleButtonGroup
                value={practice}
                onChange={(_, value) => {
                    if (value !== null) {
                        setPractice(value);
                    }
                }}
                exclusive
                unselectable="off"
                fullWidth
            >
                <ToggleButton value={true}>Practice</ToggleButton>
                <ToggleButton value={false}>Game</ToggleButton>
            </ToggleButtonGroup>

            {practice ? (
                <Typography variant="body2" px={2}>
                    Practice exercises show a single {name} at a time. After
                    proceeding to the next round you will be told the correct
                    answer.
                </Typography>
            ) : (
                <Typography variant="body2" px={2}>
                    Game exercises track your score based on the answers you
                    provided.
                </Typography>
            )}

            {!practice && (
                <Box>
                    <ToggleButtonGroup
                        value={type}
                        onChange={(_, value) => {
                            if (value !== null) {
                                setType(value);
                            }
                        }}
                        exclusive
                        unselectable="off"
                        fullWidth
                    >
                        <ToggleButton value={LessonType.FullScreen}>
                            Single {name}
                        </ToggleButton>
                        <ToggleButton value={LessonType.SplitScreen}>
                            Multiple {name}s (advanced)
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            )}

            {consolidatedType === LessonType.FullScreen && (
                <Tooltip title="Provide two options to choose from">
                    <FormControlLabel
                        sx={{flex: 1}}
                        control={
                            <Checkbox
                                checked={optionPairs ?? false}
                                onChange={(_, newValue) =>
                                    setReducedSplit(newValue)
                                }
                            />
                        }
                        disabled={customTasks.length <= 2}
                        label="Option pairs"
                    />
                </Tooltip>
            )}
            {consolidatedType === LessonType.SplitScreen && (
                <Stack gap={1} alignItems="stretch">
                    <FormControlLabel
                        labelPlacement="top"
                        control={
                            <Slider
                                min={2}
                                max={Math.max(2, customTasks.length)}
                                step={1}
                                marks={true}
                                valueLabelDisplay="auto"
                                value={split}
                                disabled={customTasks.length <= 2}
                                onChange={(_, newValue) =>
                                    setCustomSplit(newValue as number)
                                }
                            />
                        }
                        label={`${capitalize(name)}s on the screen`}
                        sx={{alignItems: 'start'}}
                    />
                    {split > 10 && (
                        <Alert severity="warning">
                            Large number of items ({split}) cannot be controlled
                            with keyboard and will only be usable on touch
                            devices.
                        </Alert>
                    )}
                </Stack>
            )}

            <Divider variant="fullWidth" sx={{my: 1}} />
            <Box>{lesson?.screenPreviewJSX ?? <ScreenPreview />}</Box>

            <Stack
                direction={{sm: 'row', xs: 'column'}}
                gap={2}
                width="100%"
                justifyContent="space-between"
            >
                <Button
                    variant="outlined"
                    component={Link}
                    to={lessonUrl}
                    disabled={customTasks.length <= 1}
                    fullWidth={true}
                >
                    How does it work?
                </Button>
                <Button
                    variant="contained"
                    component={Link}
                    to={`${lessonUrl}&start=true`}
                    disabled={customTasks.length <= 1}
                    fullWidth={true}
                    endIcon={<ForwardSharpIcon fontSize="large" />}
                >
                    {customTasks.length <= 1 ? `Select 2+ ${name}s` : 'Start'}
                </Button>
            </Stack>
        </Stack>
    );
}
