import type {PropsWithChildren} from 'react';
import React, {createContext, useContext, useState} from 'react';

type AppContextType = {
    lastLevel: number;
    setLastLevel: (level: number) => void;
}

export const AppContext = createContext<AppContextType>({
    lastLevel: 1,
    setLastLevel: () => {
    },
});

export const useAppContext = () => {
    return useContext(AppContext);
};

/**
 * Provides the app context to all children containing the last level the user has visited.
 */
export function AppContextProvider({children}: PropsWithChildren<{}>) {
    const [lastLevel, setLastLevel] = useState(1);

    return (
        <AppContext.Provider value={{lastLevel, setLastLevel}}>
            {children}
        </AppContext.Provider>
    );
}
