import React, {ReactNode} from 'react';
import {Box} from '@mui/material';

type RibbonProps = {
    label: ReactNode;
};

export function Ribbon({label}: RibbonProps) {
    return <Box sx={styles.ribbon}>{label}</Box>;
}

const styles = {
    ribbon: {
        zIndex: 500,
        margin: 0,
        background: 'orange',
        color: 'white',
        padding: '0.5rem 0',
        position: 'absolute',
        top: 0,
        right: 0,
        transform: 'translateX(30%) translateY(0%) rotate(45deg)',
        transformOrigin: 'top left',
        pointerEvents: 'none',
        ':before, :after': {
            content: '""',
            position: 'absolute',
            top: 0,
            margin: '0 -1px' /* tweak */,
            width: '100%',
            height: '100%',
            background: 'orange',
            pointerEvents: 'none',
        },
        ':before': {
            right: '100%',
        },
    },
};
