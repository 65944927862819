import {ArrowBack} from '@mui/icons-material';
import {Box, Divider, IconButton, Stack, Typography} from '@mui/material';
import React, {ReactElement, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {useAppContext} from '../../../contexts/AppContext';
import {NarrowLayout} from '../../Common/NarrowLayout';
import {LessonCard, LessonCardStack} from '../LessonCard';
import {CustomPractice} from '../../CustomPractice/CustomPractice';
import {ALL_TASKS} from '../../../sessions/tasks';
import {TaskType} from '../../../Pages/assignments';
import yellow from '../../../images/yellow.webp';

const COLORS = ALL_TASKS.filter(t => t.type === TaskType.Color);

export function Level2(): ReactElement {
    const {setLastLevel} = useAppContext();
    const navigate = useNavigate();

    useEffect(() => {
        setLastLevel(2);
    }, [setLastLevel]);

    return (
        <NarrowLayout>
            <IconButton onClick={() => navigate('/training')} sx={{mb: 2}}>
                <ArrowBack />
            </IconButton>

            <Stack direction="row" gap={2} alignItems="center">
                <Box
                    display="inline-block"
                    component="img"
                    src={yellow}
                    alt="Yellow Blindfold"
                    height="5rem"
                    width="auto"
                />
                <Box>
                    <Typography variant="h2">Colors</Typography>
                    <Typography variant="subtitle2">
                        The Yellow Blindfold
                    </Typography>
                </Box>
            </Stack>
            <Divider />

            <Typography paragraph>
                This level is designed to teach you how to recognize colors. It
                consists of multiple categories, each containing several lessons
                to help you improve your inner sight. The lessons do not need to
                be completed in any particular order, so feel free to choose
                your favorite colors to practice with. Once you feel confident
                in your skills, you can attempt to pass the tests at the end of
                this level (Coming soon).
            </Typography>

            <Divider />

            <Stack gap={4}>
                <Box>
                    <Box mb={2}>
                        <Typography variant="h3">Practice</Typography>
                        <Typography variant="subtitle2" gutterBottom>
                            Choose colors to practice with.
                        </Typography>
                    </Box>

                    <LessonCardStack>
                        <LessonCard>
                            <CustomPractice name="color" tasks={COLORS} />
                        </LessonCard>
                    </LessonCardStack>
                </Box>

                {/*<Box>*/}
                {/*    <Box mb={2}>*/}
                {/*        <Typography variant="h3">Relax</Typography>*/}
                {/*        <Typography variant="subtitle2">*/}
                {/*            No questions, just perceive colors at your own*/}
                {/*            pace.*/}
                {/*        </Typography>*/}
                {/*    </Box>*/}
                {/*    <LessonCard title="">*/}
                {/*        <Stack*/}
                {/*            direction="row"*/}
                {/*            justifyContent="center"*/}
                {/*            alignItems="center"*/}
                {/*            gap={3}*/}
                {/*            flexWrap="wrap"*/}
                {/*        >*/}
                {/*            <LessonButton lessonId="2001" />*/}
                {/*            <LessonButton lessonId="2002" />*/}
                {/*            <LessonButton lessonId="2003" />*/}
                {/*            <LessonButton lessonId="2004" />*/}
                {/*            <LessonButton lessonId="2005" />*/}
                {/*            <LessonButton lessonId="2006" />*/}
                {/*            <LessonButton lessonId="2007" />*/}
                {/*            <LessonButton lessonId="2008" />*/}
                {/*            <LessonButton lessonId="2009" />*/}
                {/*            <LessonButton lessonId="2010" />*/}
                {/*            <LessonButton lessonId="20101" />*/}
                {/*            <LessonButton lessonId="20102" />*/}
                {/*        </Stack>*/}
                {/*    </LessonCard>*/}
                {/*</Box>*/}

                {/*<Box>*/}
                {/*    <Box mb={2}>*/}
                {/*        <Typography variant="h3">Basic</Typography>*/}
                {/*        <Typography variant="subtitle2" gutterBottom>*/}
                {/*            Simple exercises with basic color combinations.*/}
                {/*        </Typography>*/}
                {/*    </Box>*/}
                {/*</Box>*/}

                {/*<Box>*/}
                {/*    <Typography variant="h3">Intermediate</Typography>*/}
                {/*    <Typography variant="subtitle2" gutterBottom>*/}
                {/*        More advanced practice with more added colors.*/}
                {/*    </Typography>*/}
                {/*</Box>*/}

                {/*<Box>*/}
                {/*    <Typography variant="h3">Advanced</Typography>*/}
                {/*    <Typography variant="subtitle2" gutterBottom>*/}
                {/*        Hardest exercises that include all available colors.*/}
                {/*    </Typography>*/}

                {/*    <LessonCardStack>*/}
                {/*        <LessonCard title="Not yet available">-</LessonCard>*/}
                {/*    </LessonCardStack>*/}
                {/*</Box>*/}
            </Stack>

            <Typography
                variant="h4"
                component="div"
                align="center"
                mt={4}
                mb={8}
            >
                More coming soon...
            </Typography>
        </NarrowLayout>
    );
}
