import React, {ReactElement} from 'react';
import {Task, TaskType} from '../../Pages/assignments';
import {Box} from '@mui/material';
import {Shape, ShapeType} from '../Common/Shape';
import {Character} from '../Common/Character/Character';
import {fullCover} from '../../styles';

export type TaskTileProps = {
    task: Task;
};

export function TaskTile({task}: TaskTileProps): ReactElement | null {
    if (!task) {
        return null;
    }
    return (
        <>
            {task.type === TaskType.Color && (
                <Box sx={[fullCover, {backgroundColor: task.value}]} />
            )}
            {task.type === TaskType.Shape && (
                <Box
                    display="flex"
                    sx={[fullCover, {p: '0.5rem', backgroundColor: 'black'}]}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Shape shape={task.value as ShapeType} color="white" />
                </Box>
            )}
            {(task.type === TaskType.Number ||
                task.type === TaskType.Letter) && (
                <Character value={task.value} />
            )}
        </>
    );
}
