import {Box} from '@mui/material';
import type {ReactElement} from 'react';
import React from 'react';

import {styles} from './styles';
import {LessonType} from '../Levels/types';
import type {Round, Task} from '../../Pages/assignments';
import {OptionMatrix} from '../OptionMatrix/OptionMatrix';
import {TaskTile} from '../TaskTile';
import TouchAppIcon from '@mui/icons-material/TouchApp';

interface PracticeRoundBoardProps {
    options: Task[];
    hideControls: boolean;
    /** If true, labels will be hidden */
    hideLabels: boolean;
    onAnswer: (answer: number) => Promise<void>;
    round: Round;
    totalRounds: number;
    tutorial: boolean;
    playAudio?: (task: Task) => void;
}

function getNumericKeyForTaskIndex(i: number): number {
    return (i + 1) % 10;
}

export function PracticeRoundBoard({
    hideControls,
    hideLabels,
    onAnswer,
    round,
    options,
    totalRounds,
    tutorial,
    playAudio,
}: PracticeRoundBoardProps): ReactElement {
    const rightAnswer = round.options[round.answerIndex];
    return (
        <>
            {round.lessonType == LessonType.FullScreen && (
                <TaskTile task={rightAnswer} />
            )}
            <OptionMatrix
                tasks={options}
                onSelect={onAnswer}
                disabled={hideControls}
                playAudioForTask={playAudio}
            >
                {(task, i) => {
                    if (!task) {
                        return null;
                    }
                    return (
                        <>
                            {round.lessonType === LessonType.FullScreen ? (
                                !hideControls && !hideLabels ? (
                                    <Box sx={sxStyles.optionLabel}>
                                        {task?.label}{' '}
                                        {task?.key?.toLowerCase() ===
                                        task?.label.toLowerCase()
                                            ? null
                                            : `[${
                                                  task.key ??
                                                  getNumericKeyForTaskIndex(i)
                                              }]`}
                                    </Box>
                                ) : null
                            ) : (
                                <TaskTile task={task} />
                            )}
                            {tutorial && i === round.answerIndex && (
                                <Box sx={sxStyles.hint}>
                                    <Box>
                                        <TouchAppIcon fontSize="large" />
                                    </Box>
                                    <Box
                                        display="inline-block"
                                        sx={{
                                            border: '1px solid white',
                                            borderRadius: '4px',
                                            width: '1.75rem',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {task?.key ??
                                            getNumericKeyForTaskIndex(i)}
                                    </Box>
                                </Box>
                            )}
                        </>
                    );
                }}
            </OptionMatrix>
            {totalRounds > 0 && (
                <Box className={styles.roundCounter}>
                    {round.order + 1}/{totalRounds}
                </Box>
            )}
        </>
    );
}

const sxStyles = {
    fullCover: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },
    optionButton: {
        '& + &': {
            borderLeft: '1px dashed gray',
        },
    },
    hint: {
        backgroundColor: 'rgba(0,0,0,0.4)',
        display: 'inline-block',
        padding: '0.5rem',
        borderRadius: '999px',
        zIndex: 2,
    },
    optionLabel: {
        position: 'absolute',
        bottom: '0',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        padding: '0.1rem 0.5rem',
        borderRadius: '3px 3px 0 0',
        lineHeight: '1.5rem',
        filter: 'drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.5))',
        pointerEvents: 'none',
        whiteSpace: 'nowrap',
        fontSize: '80%',
    },
};
