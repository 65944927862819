import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import {Box} from '@mui/material';
import {useResizeObserverZ} from '../../hooks/useResizeObserverZ';
import {calculateIdealDimensions} from '../OptionMatrix';
import useResizeObserver from '@react-hook/resize-observer';
import {UseResizeObserverCallback} from '@react-hook/resize-observer/src';
import {DOMRectReadOnly} from '@juggle/resize-observer/lib/DOMRectReadOnly';

type ZoomProps = {
    children: React.ReactNode;
};

export function Zoom({children}: ZoomProps) {
    const [scale, setScale] = useState<number>(1);
    const [zoomRef, setZoomRef] = useState<HTMLDivElement | null>(null);
    const [scaledElementRef, setScaledElementRef] =
        useState<HTMLDivElement | null>(null);
    const [visible, setVisible] = useState<boolean>(false);
    const parentRef = useRef<DOMRectReadOnly | null>(null);

    function updateChildrenScale(
        parentWidth: number,
        parentHeight: number,
        childrenWidth: number,
        childrenHeight: number,
    ) {
        const scaleX = parentWidth / childrenWidth;
        const scaleY = parentHeight / childrenHeight;
        setScale(Math.min(scaleX, scaleY));
        setVisible(true);
    }

    // useLayoutEffect(() => {
    //     setVisible(false);
    // }, [children]);

    const handleParentResize: UseResizeObserverCallback = useCallback(
        entry => {
            setTimeout(() => {
                if (!zoomRef || !scaledElementRef) {
                    return;
                }

                parentRef.current = entry.contentRect;

                updateChildrenScale(
                    entry.contentRect.width,
                    entry.contentRect.height,
                    scaledElementRef.clientWidth,
                    scaledElementRef.clientHeight,
                );
            });
        },
        [zoomRef, scaledElementRef],
    );

    useResizeObserver(zoomRef?.parentElement ?? null, handleParentResize);
    useResizeObserver(scaledElementRef ?? null, () => {
        if (!parentRef.current || !scaledElementRef) {
            return;
        }

        setVisible(false);
        updateChildrenScale(
            parentRef.current.width,
            parentRef.current.height,
            scaledElementRef.clientWidth,
            scaledElementRef.clientHeight,
        );
    });

    return (
        <Box ref={setZoomRef}>
            <Box
                ref={setScaledElementRef}
                sx={{
                    display: 'inline-block',
                    transform: `scale(${scale})`,
                    visibility: visible ? 'visible' : 'hidden',
                    transformOrigin: 'center center',
                }}
            >
                {children}
            </Box>
        </Box>
    );
}
