import {css} from '@emotion/css';

export const styles = {
    fullScreen: {
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
    },
    centered: css`
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    `,
    bottom: css`
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    `,
    withBackground: css`
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
    `,
    withDarkerBackground: css`
        background-color: rgba(0, 0, 0, 0.8);
        color: white;
    `,
    answeringProgressContainer: css`
        display: inline-block;
        border-radius: 100%;
        padding: 2em;
        //filter: drop-shadow(2px 4px 6px black);
    `,
    coloredBlock: css`
        display: inline-block;
        vertical-align: middle;
        width: 1em;
        height: 1em;
        border: 1px solid dimgray;
        border-radius: 3px;
    `,
    touchControlsContainer: css`
        display: flex;
        width: 100%;
        flex: 1;
    `,
    touchControlBorder: css`
        border-right: 1px dotted black !important;
    `,
    roundCounter: css`
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        padding: 0.2rem 1rem;
        border-radius: 0 0 3px 3px;
        line-height: 1.5rem;
        filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.5));
    `,
    optionButton: css`
        border-bottom: 1px solid rgba(0, 0, 0, 0.5);
        border-right: 1px solid rgba(0, 0, 0, 0.5);
    `,
    noInteraction: css`
        pointer-events: none;
    `,
};
