import {CssBaseline, ThemeProvider} from '@mui/material';
import React, {useEffect} from 'react';
import {Outlet, ScrollRestoration, useLocation} from 'react-router-dom';
import {AnalyticsProvider, useAnalytics} from 'use-analytics';

import './App.css';
import {AppContextProvider} from './contexts/AppContext';
import {theme} from './styles/theme';
import {ProvideAuth} from './useAuth';
import {analytics} from './Analytics';

export function App() {
    return (
        <AnalyticsProvider instance={analytics}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <ProvideAuth>
                    <AppContextProvider>
                        <ScrollRestoration />
                        <AppTracking />
                    </AppContextProvider>
                </ProvideAuth>
            </ThemeProvider>
        </AnalyticsProvider>
    );
}

export function AppTracking() {
    const location = useLocation();
    const {track, page, identify} = useAnalytics();
    useEffect(() => {
        track('pageView', {path: location.pathname});
        page({
            path: location.pathname,
        });
        // identify('user-id', {
        //     name: 'John Doe',
        //     email: '',
        // });
    }, [location.pathname, track, page, identify]);

    return <Outlet />;
}

// A wrapper for <Route> that redirects to the login

// screen if you're not yet authenticated.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// function PrivateRoute({children, ...rest}: PropsWithChildren<{}>) {
//     const auth = useAuth();
//     return (
//         <Route
//             {...rest}
//             render={({location}) =>
//                 auth.user ? (
//                     children
//                 ) : (
//                     <Redirect
//                         to={{
//                             pathname: '/login',
//                             state: {from: location},
//                         }}
//                     />
//                 )
//             }
//         />
//     );
// }
