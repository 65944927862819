import {ALL_TASKS_BY_ID} from '../../sessions/tasks';
import {Task, TaskType} from '../../Pages/assignments';
import {Box, Button, ButtonBase, Stack} from '@mui/material';
import React, {ReactElement} from 'react';
import {TaskTile} from '../TaskTile';
import {Check} from '@mui/icons-material';

export type TaskSelectorProps = {
    value: string[];
    onChange: (value: string[]) => void;
    tasks: Task[];
};

function compareFn(taskId1: string, taskId2: string) {
    const aIndex = ALL_TASKS_BY_ID[taskId1].sortingIndex ?? 0;
    const bIndex = ALL_TASKS_BY_ID[taskId2].sortingIndex ?? 0;
    return aIndex - bIndex;
}

export function TaskSelector({
    value,
    onChange,
    tasks,
}: TaskSelectorProps): ReactElement {
    const handleChange = (id: string) => {
        const newValue = value?.includes(id)
            ? (value ?? []).filter(v => v !== id)
            : [...(value ?? []), id];
        newValue.sort(compareFn);
        onChange(newValue);
    };

    return (
        <Box>
            <Stack direction="row" gap={2} mb={2}>
                <Button
                    size="small"
                    onClick={() => onChange(tasks.map(t => t.id))}
                    variant="outlined"
                >
                    Select All
                </Button>
                <Button
                    size="small"
                    onClick={() => onChange([])}
                    variant="outlined"
                >
                    Clear
                </Button>
            </Stack>
            <Box
                sx={{
                    display: 'grid',
                    gap: theme => theme.spacing(2),
                    gridTemplateColumns: {
                        sm: 'repeat(5, 1fr)',
                        xs: 'repeat(4, 1fr)',
                    },
                }}
            >
                {tasks.map(t => {
                    const checked = value?.includes(t.id);
                    return (
                        <ButtonBase
                            key={t.id}
                            sx={styles.button}
                            onClick={() => handleChange(t.id)}
                            aria-label={t.label}
                            aria-pressed={checked}
                        >
                            <TaskTile task={t} />
                            {checked && (
                                <Stack
                                    position="absolute"
                                    top={0}
                                    right={0}
                                    bottom={0}
                                    left={0}
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{
                                        backgroundColor: `rgba(0,0,0,${
                                            t.type === TaskType.Color
                                                ? 0.1
                                                : 0.5
                                        })`,
                                    }}
                                >
                                    <Check
                                        sx={{
                                            color: 'white',
                                            filter: 'drop-shadow(0 0 0.5rem rgba(0,0,0,0.5))',
                                            height: '4rem',
                                            width: '4rem',
                                        }}
                                    />
                                </Stack>
                            )}
                        </ButtonBase>
                    );
                })}
            </Box>
        </Box>
    );
}

const styles = {
    button: {
        width: '100%',
        height: '100px',
        borderRadius: '6px',
        overflow: 'hidden',
        position: 'relative',
        flex: 1,
        border: '2px solid rgba(0,0,0,0.5)',
        transition: 'transform 0.2s ease-out',
        ':active': {
            transform: 'scale(0.95)',
        },
        boxShadow: '0 0 0.5rem rgba(0,0,0,0.5)',
    },
    tile: {},
};
