import {LessonType, RelaxLessonConfig} from '../Levels/types';
import {Round, Task} from '../../Pages/assignments';
import {SessionMetrics} from '../PracticeSession/types';
import {Lesson, TOTAL_ROUNDS} from './Lesson';
import React, {
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {PracticeRoundBoard} from '../PracticeSession/PracticeRoundBoard';
import {LessonIntroProps} from './types';
import {ScreenPreview} from '../ScreenPreview';
import {Typography} from '@mui/material';
import {playRightAnswer} from './playRightAnswer';

export class RelaxLesson extends Lesson {
    constructor(
        public readonly config: RelaxLessonConfig,
        id: string | undefined = undefined,
        public readonly totalRounds = TOTAL_ROUNDS,
    ) {
        super(LessonType.Relax, id, totalRounds, config.tasks);
    }

    // there won't be any option selection, just the correct one
    public getRound(i: number): Round {
        if (i >= this.totalRounds) {
            throw new Error('No more rounds!');
        }

        const randomPick = Math.trunc(Math.random() * this.tasks.length);

        return {
            lessonType: LessonType.Relax,
            order: i,
            answerIndex: 0,
            options: [this.tasks[randomPick]],
            tasks: this.tasks,
        };
    }

    // on clicking on screen, it will say "It is red"
    // there will be only one option - the right one, but it won't say anything on selection
    public async onAnswer(round: Round, answerIndex: number): Promise<void> {
        await playRightAnswer(round.options[round.answerIndex]);
    }

    async playAudioForTask(task: Task): Promise<void> {
        // noop
    }

    // next click will switch to the new exercise

    // there won't be any evaluation at the end
    public onEvaluation(metrics: SessionMetrics) {}

    public getIntroJSX(): ReactElement {
        return (
            <>
                <Typography paragraph>
                    The practice exercises serve as an initial step towards
                    mastering the skill of this level. They aim to familiarize
                    you with the colors and shapes that you will encounter in
                    subsequent lessons. To proceed to the next color or shape,
                    you can tap the screen of your mobile device or press the
                    "1" key on your keyboard.
                </Typography>
                {this.screenPreviewJSX}
            </>
        );
    }

    public screenPreviewJSX = (<RelaxIntro lesson={this} />);
}

function RelaxIntro({lesson}: LessonIntroProps): ReactElement {
    const [counter, setCounter] = useState(0);

    const rounds = useMemo(() => {
        return Array.from({length: lesson.tasks.length}).map((_, i) => ({
            lessonType: LessonType.Relax,
            order: i,
            answerIndex: 0,
            options: [lesson.tasks[i]],
            tasks: lesson.tasks,
        }));
    }, [lesson.tasks]);

    const proceed = useCallback(() => {
        setCounter(c => (c + 1) % rounds.length);
    }, [rounds.length]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            proceed();
        }, 5000);
        return () => clearTimeout(timeout);
    }, [proceed, counter]);

    const round = rounds[counter];

    return (
        <ScreenPreview>
            <PracticeRoundBoard
                options={round.options}
                hideControls={false}
                hideLabels={false}
                onAnswer={() => Promise.resolve(proceed())}
                round={round}
                totalRounds={rounds.length}
                tutorial={true}
            />
        </ScreenPreview>
    );
}
