import {cx} from '@emotion/css';
import {ArrowBack} from '@mui/icons-material';
import {Box, Button, IconButton, Stack, Typography} from '@mui/material';
import type {ReactElement} from 'react';
import React, {useCallback, useState} from 'react';
import {styles} from './styles';
import {NarrowLayout} from '../Common/NarrowLayout';
import {useEventListener} from '../../Pages/useEventListener';
import {useAnalytics} from 'use-analytics';
import {Lesson} from '../Lesson/Lesson';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import white from '../../images/white.webp';
import ForwardSharpIcon from '@mui/icons-material/ForwardSharp';

interface SessionIntroProps {
    onStart: (tutorial: boolean) => void;
    onBack: () => void;
    lesson: Lesson;
    quickStart: boolean;
}

/**
 * This shows a preview of the lesson before starting it.
 *
 * @param onStart
 * @param onBack
 * @param lesson
 */
export function SessionIntro({
    onStart,
    onBack,
    lesson,
    quickStart,
}: SessionIntroProps): ReactElement {
    const {track} = useAnalytics();
    const [blindfolding, setBlindfolding] = useState(quickStart);

    const handler = useCallback(() => onStart(true), [onStart]);
    useEventListener('keydown', blindfolding ? handler : undefined);

    if (blindfolding) {
        return (
            <Box
                sx={[styles.fullScreen, {p: 2}]}
                display="flex"
                justifyContent="center"
                alignItems="center"
                onClick={() => onStart(false)}
            >
                <Box display="inline-block">
                    <Box textAlign="center">
                        <Box
                            component="img"
                            src={white}
                            alt="Put on your blindfold now"
                            maxWidth="100%"
                            width="auto"
                            maxHeight="200px"
                            height="auto"
                            mb={2}
                        />
                    </Box>
                    <Typography variant="h3" textAlign="center" mb={4}>
                        Put on your blindfold now.
                    </Typography>

                    <Stack direction="row" alignItems="center" gap={1} mb={2}>
                        <TouchAppIcon />
                        <Box>Tap anywhere or press any key to start.</Box>
                    </Stack>

                    <Stack direction="row" alignItems="center" gap={1}>
                        <VolumeUpIcon />
                        <Box>Turn up the volume to hear the instructions.</Box>
                    </Stack>
                </Box>
            </Box>
        );
    }

    const handleStartTutorial = async () => {
        onStart(true);

        await track('session-start', {
            tutorial: true,
            lesson: lesson.id,
        });
    };
    const handleStartPractice = async () => {
        setBlindfolding(true);

        await track('session-start', {
            tutorial: false,
            lesson: lesson.id,
        });
    };

    return (
        <Box
            className={cx(styles.withDarkerBackground)}
            p={2}
            minHeight="100vh"
        >
            <NarrowLayout>
                <IconButton onClick={onBack}>
                    <ArrowBack />
                </IconButton>

                <Typography variant="h2" gutterBottom>
                    Get ready!
                </Typography>

                {/*<Typography paragraph gutterBottom>*/}
                {/*    The following screen previews illustrate how you should*/}
                {/*    answer each task.*/}
                {/*</Typography>*/}

                <Box mb={4}>{lesson.getIntroJSX()}</Box>

                <Stack gap={2} justifyContent="space-between" direction="row">
                    <Button
                        variant="outlined"
                        fullWidth
                        size="large"
                        onClick={handleStartTutorial}
                    >
                        Demo
                    </Button>
                    <Button
                        variant="contained"
                        fullWidth
                        size="large"
                        onClick={handleStartPractice}
                        endIcon={<ForwardSharpIcon />}
                    >
                        Start
                    </Button>
                </Stack>
            </NarrowLayout>
        </Box>
    );
}
