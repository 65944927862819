import {Task} from '../../Pages/assignments';
import {playSentence} from '../../utility/playAudio';

/**
 * Play right answer audio file for given task
 * @param task Task
 */
export async function playRightAnswer(task: Task): Promise<void> {
    const answerFile = task.rightAnswerAudioFile;
    if (answerFile) {
        await playSentence(answerFile);
    } else {
        await playSentence('sounds/it_is.mp3', 20, task.audioFile);
    }
}
