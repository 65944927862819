import {ArrowBack} from '@mui/icons-material';
import {
    Alert,
    Box,
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import React, {useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import white from '../../../images/white.webp';

import {useAppContext} from '../../../contexts/AppContext';
import type {UseLocalStorageResult} from '../../../hooks/useLocalStorage';
import {useLocalStorage} from '../../../hooks/useLocalStorage';
import {NarrowLayout} from '../../Common/NarrowLayout';

const ideasReference = [
    {key: 'meditation', label: 'I have meditated with a blindfold.'},
    {
        key: 'sleep',
        label: 'I have slept with a blindfold on (if you have a sleep mask).',
    },
    {key: 'brush_teeth', label: 'I have brushed my teeth blindfolded.'},
    {key: 'eat', label: 'I have eaten a meal blindfolded.'},
    {
        key: 'walk',
        label: 'I have walked around my house/apartment blindfolded.',
    },
    {key: 'yoga', label: 'I have practiced yoga while blindfolded.'},
    {key: 'read_a_book', label: 'I have read a book about seeing blindfolded.'},
    {
        key: 'convince_others',
        label: 'I succeeded in convincing others that seeing blindfolded is real.',
    },
    {
        key: 'requires_innovation',
        label: 'I understand that my inner vision is easily bored by routine and requires ceaseless modification, diversity, innovation and originality.',
    },
    {
        key: 'practice_with_friends',
        label: 'I understand that the ideal approach is to engage in regular practice sessions with my friends!',
    },
];

export function Level1() {
    const {setLastLevel} = useAppContext();
    const navigate = useNavigate();
    const levelState = useLocalStorage<Record<string, unknown>>('level1', {});

    useEffect(() => {
        setLastLevel(1);
    }, [setLastLevel]);

    return (
        <NarrowLayout>
            <IconButton onClick={() => navigate('/training')} sx={{mb: 2}}>
                <ArrowBack />
            </IconButton>

            <Stack direction="row" gap={2} alignItems="center">
                <Box
                    display="inline-block"
                    component="img"
                    src={white}
                    alt="White Blindfold"
                    height="5rem"
                    width="auto"
                />
                <Box>
                    <Typography variant="h2">Introduction</Typography>
                    <Typography variant="subtitle2">
                        The White Blindfold
                    </Typography>
                </Box>
            </Stack>
            <Divider variant="middle" />
            <Typography paragraph>
                The white blindfold symbolizes the first stage of the inner
                sight journey, serving as a visual representation of the
                student's commitment to the practice. While it is essential to
                have an actual blindfold to start practicing, the white
                blindfold signifies more than just a physical object. It
                represents the student's understanding that the blindfold is not
                a tool to restrict their vision, but rather a tool to develop
                their natural psychic abilities. The white blindfold
                demonstrates the student's willingness to embrace the practice
                and to cultivate the skills necessary for progression.
                Therefore, the white blindfold serves as both a symbolic and
                practical tool in the development of inner sight mastery.
            </Typography>

            {/*<FormControlLabel*/}
            {/*    control={*/}
            {/*        <StepCheckbox*/}
            {/*            dataKey="have_blindfold"*/}
            {/*            levelState={levelState}*/}
            {/*        />*/}
            {/*    }*/}
            {/*    label="I have a blindfold to practice with."*/}
            {/*/>*/}
            <Alert severity="info">
                To proceed, you must have a blindfold to practice with.
            </Alert>

            <Divider variant="middle" />

            <Typography paragraph>
                To start your training, you need to get comfortable with the
                sensation of wearing a blindfold. Start by wearing a blindfold
                for short periods of time while sitting in a quiet, safe place.
                Gradually increase the time you spend with the blindfold on.
                This practice will help you develop a sense of trust and comfort
                with the blindfold.
            </Typography>

            <Typography paragraph>
                As you become more comfortable with wearing a blindfold, start
                to incorporate mindful movement into your practice. This can
                include simple movements such as walking, stretching, or yoga
                poses. Focus on the sensations in your body and the movements
                you are making. This practice will help you develop spatial
                awareness and a deeper connection with your body.
            </Typography>

            <Typography paragraph>
                Feel free to use this list of ideas as a reference while
                becoming more familiar with your blindfold and the art of seeing
                blindfolded.
            </Typography>

            <Box>
                <Stack gap={0.5}>
                    <ul>
                        {ideasReference.map(({key, label}, i) => (
                            <li key={key}>{label}</li>
                            // <FormControlLabel
                            //     key={i}
                            //     control={
                            //         <StepCheckbox
                            //             dataKey={key}
                            //             levelState={levelState}
                            //         />
                            //     }
                            //     label={label}
                            // />
                        ))}
                    </ul>
                </Stack>

                <Typography paragraph mt={2}>
                    Bonus for English speakers:
                </Typography>
                <ul>
                    <li>
                        <Box mb={1}>
                            I have told this joke to somebody while blindfolded:
                        </Box>
                        <Box fontStyle="italic">
                            - Why did the blindfolded man fall into a well?
                            <br />- Because he couldn't see that well!
                        </Box>
                    </li>
                </ul>
                {/*<FormControlLabel*/}
                {/*    control={*/}
                {/*        <StepCheckbox dataKey="joke" levelState={levelState} />*/}
                {/*    }*/}
                {/*    label="I have told this joke to somebody while blindfolded:"*/}
                {/*/>*/}
                <Stack ml={2} gap={2} />
                <Divider variant="middle" />
                {/* <Box fontStyle="italic">- Why did the blindfolded man break up with his girlfriend?<br />- Because he was seeing someone else!</Box>*/}

                <Typography paragraph gutterBottom>
                    Once you feel ready to set off on your blindfolded journey,
                    you can confirm it by checking the box below.
                </Typography>

                <Alert severity="success" icon={false} sx={{mb: 2}}>
                    <FormControlLabel
                        control={
                            <StepCheckbox
                                dataKey="completed"
                                levelState={levelState}
                            />
                        }
                        label="I vow to embrace the challenge ahead and accept the symbolic white blindfold as a testament to my dedication in mastering my inner sight."
                    />
                </Alert>

                <Button variant="outlined" component={Link} to="/">
                    Return to training
                </Button>
            </Box>
        </NarrowLayout>
    );
}

interface StepCheckboxProps {
    dataKey: string;
    levelState: UseLocalStorageResult<Record<string, unknown>>;
}

function StepCheckbox({
    levelState: [level, setLevel],
    dataKey,
}: StepCheckboxProps) {
    return (
        <Checkbox
            checked={(level?.[dataKey] as boolean) ?? false}
            onChange={e =>
                setLevel(val => ({
                    ...val,
                    [dataKey]: e.target.checked,
                }))
            }
        />
    );
}

function StepCheckboxDisabled({
    levelState: [level, setLevel],
    dataKey,
}: StepCheckboxProps) {
    return <>*</>;
}
