import {Round, Task} from '../Pages/assignments';
import {ALL_TASKS_BY_ID} from '../sessions/tasks';
import {FullScreenLessonConfig, LessonType} from '../Components/Levels/types';
import {shuffle} from './shuffle';

export function getTasksByIds(taskIds: string[]): Task[] {
    return taskIds.map(t => ALL_TASKS_BY_ID[t]).filter(t => !!t) as Task[];
}

function getSplitScreenRound(
    config: FullScreenLessonConfig,
    tasks: Task[],
    i: number,
): Round {
    const randomPick = Math.trunc(Math.random() * tasks.length);
    const shuffledTasks = shuffle([...tasks]);

    return {
        lessonType: LessonType.SplitScreen,
        order: i,
        answerIndex: randomPick,
        options: shuffledTasks,
        tasks: tasks,
    };
}

//
// function getRound(
//     config: LessonConfig,
//     tasks: Task[],
//     i: number,
// ): Round {
//     if (config.type == LessonType.Pick1) {
//         return getPick1Round(config, tasks, i);
//     } else if (config.type == LessonType.Pick1SplitScreen) {
//         return getPick1SplitScreenRound(config, tasks, i);
//     }
//     throw new Error('Unknown lesson type');
// }
