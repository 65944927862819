// makes playing audio return a promise

import {Howl} from 'howler';
import {delay} from './delay';

function playAudioCallback(file: string, resolve: () => void) {
    const audio = new Howl({
        src: [`${process.env.PUBLIC_URL}/${file}`],
        onend() {
            resolve();
        },
    });

    audio.play();

    return audio;
}

/**
 * Plays an audio file and returns a promise that resolves when the audio is done playing
 * @param file
 */
export function playAudio(file: string): Promise<void> {
    return new Promise<void>(resolve => {
        playAudioCallback(file, resolve);
    });
}

type PlayingSentence = {
    audio: Howl | null;
    playing: boolean;
};

let playingSentence: PlayingSentence | null = null;

export async function playSentence(...sounds: (string | number)[]) {
    const sentence: PlayingSentence = {
        audio: null,
        playing: true,
    };

    // Stop the current sentence if it exists
    if (playingSentence) {
        playingSentence.audio?.stop();
        playingSentence.playing = false;
    }
    playingSentence = sentence;

    // Iterate over each sound in the sentence
    for (const sound of sounds) {
        // Break out of the loop if a new sentence has started
        if (!sentence.playing) {
            break;
        }

        if (typeof sound === 'string') {
            let src = sound;
            // Remove leading slash if it exists (Howler doesn't like it)
            src = src[0] == '/' ? src.substring(1) : src;

            const audio = new Howl({
                src: src,
                html5: true,
            });
            await playAudio(sound);
            sentence.audio = audio;
        } else if (typeof sound === 'number') {
            sentence.audio = null;
            await delay(sound);
        }
    }

    playingSentence = null;
}
