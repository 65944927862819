import type {Task} from '../Pages/assignments';
import {TaskType} from '../Pages/assignments';
import {capitalize} from 'lodash';

export const ALL_TASKS: Task[] = [
    {
        id: 'white',
        label: 'White',
        value: 'white',
        type: TaskType.Color,
        audioFile: 'sounds/colors/white.mp3',
        rightAnswerAudioFile: 'sounds/colors/it_is_white.mp3',
    },
    {
        id: 'pink',
        label: 'Pink',
        value: '#fcaad1',
        type: TaskType.Color,
        audioFile: 'sounds/colors/pink.mp3',
        rightAnswerAudioFile: 'sounds/colors/it_is_pink.mp3',
    },
    {
        id: 'yellow',
        label: 'Yellow',
        value: '#FFF600',
        type: TaskType.Color,
        audioFile: 'sounds/colors/yellow.mp3',
        rightAnswerAudioFile: 'sounds/colors/it_is_yellow.mp3',
    },
    {
        id: 'orange',
        label: 'Orange',
        value: 'orange',
        // value: '#ffb436',
        type: TaskType.Color,
        audioFile: 'sounds/colors/orange.mp3',
        rightAnswerAudioFile: 'sounds/colors/it_is_orange.mp3',
    },
    {
        id: 'red',
        label: 'Red',
        value: '#D10000',
        type: TaskType.Color,
        audioFile: 'sounds/colors/red.mp3',
        rightAnswerAudioFile: 'sounds/colors/it_is_red.mp3',
    },
    {
        id: 'green',
        label: 'Green',
        // value: '#11a911',
        value: 'green ',
        type: TaskType.Color,
        audioFile: 'sounds/colors/green.mp3',
        rightAnswerAudioFile: 'sounds/colors/it_is_green.mp3',
    },
    {
        id: 'blue',
        label: 'Blue',
        // value: 'blue',
        value: '#002a96',
        type: TaskType.Color,
        audioFile: 'sounds/colors/blue.mp3',
        rightAnswerAudioFile: 'sounds/colors/it_is_blue.mp3',
    },
    {
        id: 'brown',
        label: 'Brown',
        value: '#602f00',
        // value: '#733900',
        type: TaskType.Color,
        audioFile: 'sounds/colors/brown.mp3',
        rightAnswerAudioFile: 'sounds/colors/it_is_brown.mp3',
    },
    {
        id: 'purple',
        label: 'Purple',
        value: '#800080',
        type: TaskType.Color,
        audioFile: 'sounds/colors/purple.mp3',
        rightAnswerAudioFile: 'sounds/colors/it_is_purple.mp3',
    },
    {
        id: 'black',
        label: 'Black',
        value: '#000000',
        type: TaskType.Color,
        audioFile: 'sounds/colors/black.mp3',
        rightAnswerAudioFile: 'sounds/colors/it_is_black.mp3',
    },

    ...[
        'circle', // 0
        'heart', // 2
        'triangle', // 3
        'square', // 4
        'rectangle', // 4
        'diamond', // 4
        'pentagon', // 5
        'arrow', // 7
        'star', // 10
        'plus', // 12
    ].map(shape => ({
        id: shape,
        label: capitalize(shape),
        value: shape,
        type: TaskType.Shape,
        audioFile: `sounds/shapes/${shape}.mp3`,
        rightAnswerAudioFile: `sounds/shapes/it_is_${shape}.mp3`,
    })),

    ...'1234567890'.split('').map(number => ({
        id: number,
        label: number,
        value: number,
        type: TaskType.Number,
        audioFile: `sounds/numbers/${number}.mp3`,
        key: String(number),
    })),

    ...'abcdefghijklmnopqrstuvwxyz'.split('').map(letter => ({
        id: letter,
        label: letter.toUpperCase(),
        value: letter.toUpperCase(),
        type: TaskType.Letter,
        audioFile: `sounds/letters/${letter}.mp3`,
        key: letter,
    })),
];

export const ALL_TASKS_BY_ID = ALL_TASKS.reduce((acc, next, index) => {
    acc[next.id] = {
        ...next,
        sortingIndex: index,
    };
    return acc;
}, {} as Record<string, Task>);
